/* eslint-disable */
import { createGlobalStyle } from 'styled-components';
export const GlobalStyles = createGlobalStyle`
  .wrapper-bg-color {
    background: ${({ theme }) => theme.wrapperBackground};
  }
  .bg-color {
    background: ${({ theme }) => theme.background};
  }
  .module-title-color {
    color: ${({ theme }) => theme.header.titleColor};
  }
  .header-text-color {
    color: ${({ theme }) => theme.header.color};
  }
  .text-white-color {
    color: ${({ theme }) => theme.whiteColor};
  }
  .text-gray-color {
    color: ${({ theme }) => theme.grayColor};
  }
  .text-graywhite-color {
    color: ${({ theme }) => theme.grayWhiteColor};
  }
  #locationShareBeta table td:nth-child(1) .link-inactive{
    color: ${({ theme }) =>
      theme.findMyTruckStyles.locationShareLabel.color} !important;
    font-weight: ${({ theme }) =>
      theme.findMyTruckStyles.locationShareLabel.fontWeight} !important;
  }
  .react-datepicker__month-container, .react-datepicker__tab-loop,
  .react-datepicker-popper .react-datepicker .react-datepicker__header,
  .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker,
  .react-datepicker__time-container .react-datepicker__time  {
    background-color: ${({ theme, isDarkThemeSuprted, darkTheme }) =>
      isDarkThemeSuprted
        ? darkTheme.datepicker.background
        : theme.datepicker.background} !important;
  }
  .react-datepicker__day, .react-datepicker__time-list-item,
  .react-datepicker__current-month, .react-datepicker-time__header,
  .react-datepicker-year-header, .react-datepicker__day-name,
  .react-datepicker__input-container input {
    color: ${({ theme, isDarkThemeSuprted, darkTheme }) =>
      isDarkThemeSuprted
        ? darkTheme.datepicker.color
        : theme.datepicker.color} ;
  }
  .react-datepicker__day--disabled, .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    color: #a69d9d !important;
  }
  .react-datepicker__week .react-datepicker__day--disabled {
    color: #a69d9d !important;
  }
  .alerts-btn, .filterBtn {
    background: ${({ theme }) => theme.alerts.btn.background};
    color: ${({ theme }) => theme.alerts.btn.color};
    border-radius: ${({ theme }) => theme.alerts.btn.radius};
    border: ${({ theme }) => theme.alerts.btn.border};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  .filterBtn:hover{box-shadow:none;}
  .cardPrimary {
    background: ${({ theme }) => theme.cardPrimary.background};
    color: ${({ theme }) => theme.cardPrimary.color};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px
  }
  .cardSecondary {
    background: ${({ theme }) => theme.cardSecondary.background};
    color: ${({ theme }) => theme.cardSecondary.color};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .alert-list-bg {
    background: ${({ theme }) => theme.cardOther.background};
    color: ${({ theme }) => theme.cardOther.color};
    border-radius: ${({ theme }) => theme.cardOther.radius};
  }
  .alert-list-card {
    background: ${({ theme }) => theme.cardPrimary.background};
    color: ${({ theme }) => theme.cardPrimary.color};
    border-radius: ${({ theme }) => theme.cardPrimary.radius};
    border: ${({ theme }) => theme.cardPrimary.border};
  }
  #repTabletheme .table thead {
    background-color: ${({ theme }) => theme.reports.tableHeader} !important;
    color:${({ theme }) => theme.reports.tableHeaderFont} !important;
    border: 2px solid ${({ theme }) => theme.reports.tableBorder} !important;
  }
  #repTabletheme .table thead tr th {
    border: 2px solid ${({ theme }) => theme.reports.tableBorder} !important;
    text-align: center;
    font-size: 14px !important;
    padding: 0.25rem;
    vertical-align: inherit;
  }
  #repTabletheme .table tbody tr {
    background-color: ${({ theme }) => theme.reports.even} !important;
  }
  #repTabletheme .table tbody tr:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.reports.odd} !important;
  }
  #repTabletheme .table td{
    color: ${({ theme }) => theme.reports.fontTable} !important;
    border: ${({ theme }) => theme.reports.border} !important;
    text-align: center;
  }
  .table-container tr > td:first-of-type {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #060818;
 }
  .table-container tr:nth-child(odd) > td:nth-child(1) {
    background: ${({ theme }) => theme.reports.odd} !important;
 }
  .table-container tr:nth-child(even) > td:nth-child(1) {
    background: ${({ theme }) => theme.reports.even} !important;
 }
  .table-container th:nth-child(1) {
    z-index: 3;
 }
.drivingAnalysisContainer tr > td:first-of-type {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #060818;
}
.drivingAnalysisContainer tr:nth-child(odd) > td:nth-child(1) {
  background: ${({ theme }) => theme.reports.odd} !important;
}
.drivingAnalysisContainer tr:nth-child(even) > td:nth-child(1) {
  background: ${({ theme }) => theme.reports.even} !important;
}
.drivingAnalysisContainer tr:nth-child(odd) > td:nth-child(2){
  background: ${({ theme }) => theme.reports.odd} !important;
  position: sticky;
  top: 0;
  left: 182px;
  z-index: 2;
  background-color: #060818;
}
.drivingAnalysisContainer tr:nth-child(even) > td:nth-child(2){
  background: ${({ theme }) => theme.reports.even} !important;
  position: sticky;
  top: 0;
  left: 182px;
  z-index: 2;
}
.drivingAnalysisContainer tr:nth-child(odd) > td:nth-child(3){
  background: ${({ theme }) => theme.reports.odd} !important;
  position: sticky;
  top: 0;
  Left:308px;
  z-index: 2;
  background-color: #060818;
}
.drivingAnalysisContainer tr:nth-child(even) > td:nth-child(3){
  background: ${({ theme }) => theme.reports.even} !important;
  position: sticky;
  top: 0;
  Left:308px;
  z-index: 2;
}
.drivingAnalysisContainer th:nth-child(1) {
  padding:0 40px;
  z-index: 3;

}
.drivingAnalysisContainer th:nth-child(2) {
  z-index: 3;
  Left:182px;
  position:sticky !important;
}
.drivingAnalysisContainer th:nth-child(3) {
  z-index: 3;
  Left:308px;
  position:sticky;
}
.menu-dropdown .dropdown-container input[type="checkbox"]:checked {
  background: ${({ theme }) =>
    theme.drivingAnalysisStyles.checkedStyle.backgroundImage} !important;
  background-repeat: no-repeat;
  background-size: contain;
}
.react-datepicker__day, .react-datepicker__time-list-item, .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__input-container input {
  color: ${({ theme }) =>
    theme.drivingAnalysisStyles.calenderDisabledates.color} !important;
}

.tripCard {
  background-color: ${({ theme }) => theme.trackTrace.tripCard.bgColor};
  border-radius: 5px;
  cursor: pointer;
}
.geoFencCardHead {
  background-color: ${({ theme }) => theme.trackTrace.geoFencCardHead.bgColor};
  border-radius: 5px;
}
.geoFencCard { 
  background-color: ${({ theme }) => theme.trackTrace.geoFencCard.bgColor};
  border-radius: 5px;
  height: 55px
}
.create-trip-btn {
  background: transparent;
  color: ${({ theme }) => theme.tripManagement.createBtn.color};
  border: 2px solid ${({ theme }) => theme.tripManagement.createBtn.color};
  font-weight: bold;
}
.share-all-btn {
  background: transparent;
  color: ${({ theme }) => theme.tripManagement.shareBtn.color};
  border: 2px solid ${({ theme }) => theme.tripManagement.shareBtn.color};
  font-weight: bold;
}
.end-trip-btn {
  background: transparent;
  color: ${({ theme }) => theme.tripManagement.endBtn.color};
  border: 2px solid ${({ theme }) => theme.tripManagement.endBtn.color};
  font-weight: bold;
}
.main-wrapper-theme {
  background: ${({ theme }) => theme.dashboardStyles.mainWrapper.bgColor}
} 
.owner-wrapper-theme {
  background: ${({ theme }) => theme.dashboardStyles.ownerWrapper.bgColor}
}
.clearButtonDashboard{
  background: ${({ theme }) =>
    theme.dashboardStyles.clearButton.bgColor} !important;
  color: ${({ theme }) => theme.dashboardStyles.clearButton.color} !important;
}
.trip-analysis .reportFilters .form-group label {
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.tripManagementTable.color};
}

.ant-calendar-input-wrap {
  background: ${({ theme }) =>
    theme.tripManagementLightTheme.calenderInput.bgColor};
}
.tableWrapperAnalysys .table thead tr th{
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.tripManagementTable.color};
  border:  ${({ theme }) => theme.reportsStyles.tableBorder.border} !important;
  background: 
  ${({ theme }) => theme.reportsStyles.reportsTableEven.bgColor} !important;
}
.tableWrapperAnalysys table tr td{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.tripManagementTd.bgColor} !important;
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.tripManagementTd.color} !important;
    border:  ${({ theme }) =>
      theme.reportsStyles.tableBorder.border} !important;
}
.tableWrapperAnalysys table tr:nth-of-type(odd){
  background: 
  ${({ theme }) => theme.reportsStyles.reportsTable.bgColor} !important;
  color: ${({ theme }) => theme.reportsStyles.reportsTable.color} !important;
}
.tableWrapperAnalysys table tr:nth-of-type(even){
  background: 
  ${({ theme }) => theme.reportsStyles.reportsTableEven.bgColor} !important;
  color: ${({ theme }) =>
    theme.reportsStyles.reportsTableEven.color} !important;
}
.report-header {
  height: 4.2rem;
  .active{
    border-bottom: ${({ theme }) =>
      theme.tripManagementLightTheme.reportsTabActive.borderBottom} !important;
    color: ${({ theme }) =>
      theme.tripManagementLightTheme.reportsTabActive.color} !important;
  }
}



.createTripContainer{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.bgColor} !important;
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.color} !important;
}
.formContainer .form-group input{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.bgColor} !important;
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.color} !important;
}
.formContainer .form-group{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.bgColor} !important;
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.color} !important;
}
.formContainer .form-group input::placeholder{
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.color} !important;
}
.tripHeading h3{
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.color} !important;
}
.createTripContainer .formContainer .form-group select{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.bgColor} !important;
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.color} !important;
}
.cancelTrip {
  background: ${({ theme }) =>
    theme.tripManagementLightTheme.cancelTripButton.bgColor};
}
.formContainer .form-group-new input[type="text"]{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.bgColor} !important;
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.color} !important;
}
.submitButton{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModalSubitnBtn.bgColor} !important;
  color: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModalSubitnBtn.color} !important;
}
.share-new{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModalSubitnBtn.bgColor} !important;
  color: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModalSubitnBtn.color} !important;
}
.ant-calendar-date-panel{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.tripManagementCalender.bgColor} !important;
  color: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.tripManagementCalender.color} !important;
}
.ant-calendar-header .ant-calendar-prev-year-btn{
  color: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.tripManagementCalender.color} !important;
}

.endTripContainer .endTripModal{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.bgColor} !important;
}
.endTripContainer .endTripModal .content{
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModal.color} !important;
  font-weight:bolder;
}
.btn.ok-trip-btn {
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModalSubitnBtn.bgColor} !important;
  color: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.createTripModalSubitnBtn.color} !important;
}
.leftSidePanel{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.sideArrowContent.bgColor} !important;
  color: ${({ theme }) =>
    theme.tripManagementLightTheme.sideArrowContent.color} !important;
}



.map-sidebar{
  background: ${({ theme }) => theme.findMyTruckStyles.mapSidebar.bgColor};
  color: ${({ theme }) => theme.findMyTruckStyles.mapSidebar.color};
  border: ${({ theme }) => theme.findMyTruckStyles.mapSidebar.border};
}
.filter-panel{
  background: ${({ theme }) => theme.findMyTruckStyles.filterFmt.bgColor};
  color: ${({ theme }) => theme.findMyTruckStyles.filterFmt.color}
}
.expleftSidePanel{
  background: ${({ theme }) => theme.findMyTruckStyles.fmtScroll.bgColor};
  color: ${({ theme }) => theme.findMyTruckStyles.fmtScroll.color}
}
.filter-btn{
  background: ${({ theme }) => theme.findMyTruckStyles.filterBtn.bgColor};
  color: ${({ theme }) => theme.findMyTruckStyles.filterBtn.color}
}
.status-panel{
  background: ${({ theme }) => theme.findMyTruckStyles.statusPanel.bgColor};
  color: ${({ theme }) => theme.findMyTruckStyles.statusPanel.color};
  border: ${({ theme }) => theme.findMyTruckStyles.statusPanel.border};
}
.fmt-search{
  border: ${({ theme }) => theme.findMyTruckStyles.fmtSearch.border};
}
.show-button{
  color: ${({ theme }) => theme.findMyTruckStyles.showBtn.color};
  border: ${({ theme }) => theme.findMyTruckStyles.showBtn.border};
}
.footerWrapper{
  background: ${({ theme }) => theme.findMyTruckStyles.footerWrapper.bgColor};
  color: ${({ theme }) => theme.findMyTruckStyles.footerWrapper.color};
}
.footerWrapper li, .footerWrapper li a, .footerWrapper .header-text-color {
  color: ${({ theme }) => theme.findMyTruckStyles.footerWrapper.color};
}
}
.fmt-tab{
  color: ${({ theme }) => theme.findMyTruckStyles.fmtTab.color};
}
.group-drop-fmt-search .dropdown-container, .group-drop-fmt-search, .search-scroll-div{
  background-color: ${({ theme }) =>
    theme.findMyTruckStyles.findMyTruckDropdown.bgColor};
  color: ${({ theme }) => theme.findMyTruckStyles.findMyTruckDropdown.color};
}
.search-btn-fmt{
  background: ${({ theme }) =>
    theme.findMyTruckStyles.findMyTruckDropdownBtn.bgColor};
  color: ${({ theme }) => theme.findMyTruckStyles.findMyTruckDropdownBtn.color};
}
.search-btn-fmt{
  background: ${({ theme }) =>
    theme.findMyTruckStyles.findMyTruckDropdownBtn.bgColor};
  color: ${({ theme }) => theme.findMyTruckStyles.findMyTruckDropdownBtn.color};
}
.geo-head{
  color: ${({ theme }) => theme.fmtGeofenceStyles.geoHeader.color};
}
.geo-name{
  color: ${({ theme }) => theme.fmtGeofenceStyles.geoName.color};
}
.geo-box {
  background: ${({ theme }) => theme.fmtGeofenceStyles.geoBox.bgColor};
  color: ${({ theme }) => theme.fmtGeofenceStyles.geoBox.color};
}
#locationShareList table td{
  color: ${({ theme }) => theme.fmtGeofenceStyles.locationList.color};
  background: ${({ theme }) => theme.fmtGeofenceStyles.locationList.bgColor};
}
#locationShareList table th{
  color: ${({ theme }) => theme.fmtGeofenceStyles.locationList.color};
  background: ${({ theme }) => theme.fmtGeofenceStyles.locationList.bgColor};
}
.report-header{
  background: ${({ theme }) => theme.reportsStyles.reportsHeader.bgColor};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.filterInfoBox{
  color: ${({ theme }) => theme.filterInfoBoxStyles.filterMessage.color};
}
.menu-dropdown ul input[type=text]{
  color: ${({ theme }) => theme.reportsStyles.inputPlaceholderReports.color};
  background: ${({ theme }) =>
    theme.reportsStyles.inputPlaceholderReports.bgColor};
  border: ${({ theme }) => theme.reportsStyles.inputPlaceholderReports.border};
}
.menu-dropdown .dropdown-container {
  color: ${({ theme }) => theme.dashboardStyles.dropdownStyleDA.color};
}
.panel.reports{
  background: ${({ theme }) => theme.reportsStyles.filterScreen.bgColor};
  margin-top: 20px;
}
.distance-alert{
  background: ${({ theme }) => theme.reportsStyles.distanceAlert.bgColor};
  color: ${({ theme }) => theme.reportsStyles.distanceAlert.color};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.react-datepicker__month-container, .react-datepicker__tab-loop,
.react-datepicker-popper .react-datepicker .react-datepicker__header,
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker,
.react-datepicker__time-container .react-datepicker__time, 
.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker-time__header,
.react-datepicker__time-list {
  background: 
  ${({ theme }) => theme.reportsStyles.datePicker.bgColor} !important;
  color: ${({ theme }) => theme.reportsStyles.datePicker.color} !important;
}
.btn.submit-fuel-button{
  background: ${({ theme }) => theme.reportsStyles.repButton.bgColor};
  color: ${({ theme }) => theme.reportsStyles.repButton.color};
}
#batch-menu-drop{
  background: ${({ theme }) => theme.reportsStyles.menuDropDown.bgColor};
  color: ${({ theme }) => theme.reportsStyles.menuDropDown.color};
}
.batch-filter-block{
  background: ${({ theme }) => theme.reportsStyles.filterBlock.bgColor};
}
.vehicle-text{
  color:  ${({ theme }) => theme.reportsStyles.selectVehicleText.color};
}
.clear-btn-trip{
  background: ${({ theme }) => theme.reportsStyles.clearButton.bgColor};
  color: ${({ theme }) => theme.reportsStyles.clearButton.color};
}
.panel_container .table {
  background: ${({ theme }) => theme.reportsStyles.reportsTable.bgColor};
  color: ${({ theme }) => theme.reportsStyles.reportsTable.color};
}
#repTable .table td{
  border:  ${({ theme }) => theme.reportsStyles.tableBorder.border} !important;
}
#repTable .table thead tr th {
  border:  ${({ theme }) => theme.reportsStyles.tableBorder.border} !important;
}
#repTable .table thead {
  border:  ${({ theme }) => theme.reportsStyles.tableBorder.border} !important;
}
#repTable .table th {
  background: 
  ${({ theme }) => theme.reportsStyles.reportsTableEven.bgColor} !important;
  color: ${({ theme }) => theme.reportsStyles.reportsTable.color} !important;
}
#repTable .table tr:nth-of-type(odd){
  background: 
  ${({ theme }) => theme.reportsStyles.reportsTable.bgColor} !important;
  color: ${({ theme }) => theme.reportsStyles.reportsTable.color} !important;
}
#repTable .table tr:nth-of-type(even){
  background: 
  ${({ theme }) => theme.reportsStyles.reportsTableEven.bgColor} !important;
  color: ${({ theme }) =>
    theme.reportsStyles.reportsTableEven.color} !important;
}
.navTab ul{
  background: ${({ theme }) => theme.reportsStyles.alertsNavTab.bgColor};
  color: ${({ theme }) => theme.reportsStyles.alertsNavTab.color};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
#alertReport-new .navTab ul li.active {
  background: 
  ${({ theme }) => theme.reportsStyles.alertsNavTabActive.bgColor} !important;
  color: ${({ theme }) =>
    theme.reportsStyles.alertsNavTabActive.color} !important;
}
.noDataLegend {
  color: ${({ theme }) => theme.dashboardStyles.noDataLegend.color};
}
.noDataLegend.dashPanel {
  color: ${({ theme }) => theme.dashboardStyles.dashPanel.color};
}
.home-text-theme {
  color: ${({ theme }) => theme.dashboardStyles.homeText.color};
}
.dashboard-select {
  background:${({ theme }) => theme.dashboardStyles.dashboardSelect.bgColor};
}
.vehicle-list-item:hover {
  color:${({ theme }) => theme.dashboardStyles.dashboardSelect.color}
}
#alertReport-new .navTab ul li {
  color: #a1a1a1 !important;
}
.owner-wrapper {
  background: ${({ theme }) => theme.dashboardStyles.ownerWrapper.bgColor}
} 
.card-background-theme {
  background: ${({ theme }) => theme.dashboardStyles.cardBackground.bgColor}
} 
.card-title-theme {
  color: ${({ theme }) => theme.dashboardStyles.cardTitle.color};
} 
.highcharts-background {
  fill: ${({ theme }) => theme.dashboardStyles.highchartsBackground.fill};
}
.noDataLegend {
  color: ${({ theme }) => theme.dashboardStyles.noDataLegend.color};
}
.home-text-theme {
  color: ${({ theme }) => theme.dashboardStyles.homeText.color};
  background: url('/assets/imagesA_trip_ploting.png');
}
.dashboard-select {
  background:${({ theme }) => theme.dashboardStyles.dashboardSelect.bgColor};
}
.serviceInfoContainer {
  background-color:${({ theme }) =>
    theme.dashboardStyles.serviceInfoContainer.bgColor} !important;
}
.serviceInfoContainer .remainderText {
  color:${({ theme }) => theme.dashboardStyles.serviceInfoContainer.color};
}
.serviceInfoContainer .serviceLoading1 {
  color:${({ theme }) => theme.dashboardStyles.serviceInfoContainer.color};
}
.CircularProgressbar-trail {
  stroke: ${({ theme }) =>
    theme.dashboardStyles.CircularProgressbarTrail.stroke};
}
.trip-analysis .reportFilters .create-btn1 button.btn-submit-dash-theme,
.create-btn.btn-submit-dash-theme,
.btn-submit-dash-theme {
  background:${({ theme }) => theme.dashboardStyles.buttonSubmit.bgColor};
  color:${({ theme }) => theme.dashboardStyles.buttonSubmit.color};
}
.serviceNowButton .buttonCancel {
  background:${({ theme }) => theme.dashboardStyles.buttonCancel.bgColor};
}
.serviceNowButton .buttonCancel span {
  color:${({ theme }) => theme.dashboardStyles.buttonCancel.color} !important;
}
.initial-truck {
  color:${({ theme }) => theme.dashboardStyles.initialTruck.color};
}
.downArrow path {
  fill:${({ theme }) => theme.dashboardStyles.downArrow.fill};
}
.dropdownStyle,
.health-wrapper .menu-dropdown.dropdownStyle {
  background:${({ theme }) => theme.dashboardStyles.dropdownStyle.bgColor};
  border:${({ theme }) => theme.dashboardStyles.dropdownStyle.border};
}
.owner-wrapper-analysis .analysis-wrapper .dropdownDriver {
  background:${({ theme }) => theme.dashboardStyles.dropdownStyleDA.bgColor};
  color:${({ theme }) => theme.dashboardStyles.dropdownStyleDA.color};
  border:${({ theme }) => theme.dashboardStyles.dropdownStyleDA.border};

}
.dropdownStyle .dropdown-container,
.owner-wrapper-analysis .analysis-wrapper .dropdownDriver .dropdown-container {
  color:${({ theme }) => theme.dashboardStyles.dropdownStyle.color};
}
.menu-search-truck {
  background:${({ theme }) => theme.dashboardStyles.menuSearchtruck.bgColor};
  color:${({ theme }) =>
    theme.dashboardStyles.menuSearchtruck.color} !important;
  border:${({ theme }) =>
    theme.dashboardStyles.menuSearchtruck.border} !important;
}
.dropdownCalendar select {
  background:${({ theme }) =>
    theme.dashboardStyles.dropDownCalendar.background};
  color:${({ theme }) => theme.dashboardStyles.dropDownCalendar.color};
}
.dropdownCalendar select option {
  background:${({ theme }) =>
    theme.dashboardStyles.inputSelectOption.background};
  color:${({ theme }) => theme.dashboardStyles.inputSelectOption.color};
}
.inputField-dash {
  background:${({ theme }) => theme.dashboardStyles.inputFieldDash.background};
}
.group-drop-dash input[type="checkbox"] {
  filter:${({ theme }) => theme.dashboardStyles.groupDropDash.color};
}
.group-drop-dash input[type="checkbox"]:checked {
  filter:${({ theme }) => theme.dashboardStyles.groupDropDashChecked.color};
}
.analysis-title-theme {
  color: ${({ theme }) => theme.drivingAnalysisStyles.analysisTitle.color};
  font-weight:bold;
}
.noDataLegend-driving-theme {
  color: ${({ theme }) => theme.drivingAnalysisStyles.noDataDriving.color};
}
.owner-wrapper-analysis .analysis-wrapper .date-container-driver.data-container-theme {
  background: ${({ theme }) => theme.genericStyles.dataContainer.bgColor}; 
}
.owner-wrapper-analysis .analysis-wrapper .date-container-driver.data-container-theme .date-fleet .react-datepicker-wrapper input {
  background: ${({ theme }) => theme.genericStyles.dataContainer.bgColor};
  color: ${({ theme }) => theme.genericStyles.dataContainer.color} !important;
}
.owner-wrapper-analysis .analysis-wrapper .date-container-driver.data-container-theme .date-fleet .date-label {
  color: ${({ theme }) => theme.genericStyles.dataContainer.color};
}
.setting-inner-left-panel {
  background: ${({ theme }) => theme.settingsStyles.settingsLeftPanel.bgColor};
  color: ${({ theme }) => theme.settingsStyles.settingsLeftPanel.color};
  padding-top:0;
}
.CDSCutomer .table-header,
.setting-right-panel .group-setting-heading.tableHeading {
  background: ${({ theme }) => theme.settingsStyles.settingsTableHead.bgColor};
  color: ${({ theme }) =>
    theme.settingsStyles.settingsTableHead.color} !important;
  border: ${({ theme }) => theme.settingsStyles.settingsTableHead.border};
  border-bottom-width: 0.5px;
}
.CDSCutomer .table-body:nth-child(odd),
.setting-right-panel .group-name-setting:nth-child(odd) {
  background: ${({ theme }) => theme.settingsStyles.settingsTableOdd.bgColor};
  color: ${({ theme }) => theme.settingsStyles.settingsTableOdd.color};
  border: ${({ theme }) => theme.settingsStyles.settingsTableOdd.border};
}
.CDSCutomer .table-body:nth-child(even),
.setting-right-panel .group-name-setting:nth-child(even) {
  background: ${({ theme }) => theme.settingsStyles.settingsTableEven.bgColor};
  color: ${({ theme }) => theme.settingsStyles.settingsTableEven.color};
  border: ${({ theme }) => theme.settingsStyles.settingsTableEven.border};
}
.setting-right-panel .accordionContent .form-group .inputField {
  background: ${({ theme }) => theme.settingsStyles.accordionContent.bgColor};
  color: ${({ theme }) => theme.settingsStyles.accordionContent.color};
}
.activeLeftMenu {
  background: ${({ theme }) => theme.settingsStyles.activeLeftMenu.bgColor};
  color: ${({ theme }) => theme.settingsStyles.activeLeftMenu.color};
}
.groups-setting-tittle, 
.groups-setting-add, 
.pass-title, 
.heading-alert, 
.text-color-alert, 
.inner-text-color-alert,
.alert-setting-heading,
.alert-setting .form-custom-label {
  color: ${({ theme }) => theme.settingsStyles.settingsHeader.color};
}
.accordionContainer .checkBoxStyle.ChkBox label {
  border: ${({ theme }) => theme.settingsStyles.checkBoxStyle.border};
}
.accordionContainer .checkBoxStyle input[type="checkbox"]:checked + label {
  background: ${({ theme }) => theme.settingsStyles.checkBoxStyle.bgColor};
  border: ${({ theme }) => theme.settingsStyles.checkBoxStyle.border};
}
.cds .MuiBox-root .MuiPaper-root {
  background: ${({ theme }) => theme.cds.stepper.bgColor} !important;
}
.cds .MuiBox-root .MuiPaper-root .MuiStep-horizontal .MuiStepLabel-horizontal .MuiStepLabel-labelContainer,
.cds .MuiBox-root .MuiPaper-root .MuiStep-horizontal .MuiStepLabel-horizontal .MuiStepLabel-labelContainer .MuiStepLabel-label {
  color: ${({ theme }) => theme.cds.stepper.color} !important;

}
.footerList.colorwhite {
  color: ${({ theme }) => theme.genericStyles.footerText.color} !important;
}
.footerList.colorwhite a {
  color: ${({ theme }) => theme.genericStyles.footerText.color} !important;
}
.graph-heading {
  color: ${({ theme }) => theme.tripManagement.tripCardHeader.color};
}
.tripNoDataLegend {
  color: ${({ theme }) => theme.tripManagement.tripNoDataLegend.color};
}
.panelHeading-trip {
  background: ${({ theme }) => theme.tripManagement.tripTableHeader.bgColor};
}
.trip-tab, .trip-tab p {
  background: ${({ theme }) => theme.tripManagement.tripTableHeader.bgColor};
  color: ${({ theme }) => theme.tripManagement.tripTableHeader.color};
}
.activeTabHeader, .activeTabHeader p {
  background: ${({ theme }) =>
    theme.tripManagement.tripTableHeaderActive.bgColor};
    color: ${({ theme }) => theme.tripManagement.tripTableHeaderActive.color};
}
.vehilefilter-truck{
  background: ${({ theme }) =>
    theme.tripManagement.driveSearchDropdown.bgColor};
  color: ${({ theme }) => theme.tripManagement.driveSearchDropdown.color};
}

.health-indicator {
  background: ${({ theme }) => theme.fleetHealth.healthIndicator.bgColor};
}
.health-carousel {
  background: ${({ theme }) => theme.fleetHealth.healthIndicator.bgColor};
  border-bottom: ${({ theme }) =>
    theme.fleetHealth.healthIndicator.borderBottom};
  box-shadow: ${({ theme }) => theme.fleetHealth.healthIndicator.boxShadow};
}
.imm-img {
  background: ${({ theme }) => theme.fleetHealth.healthIndicator.innerBg};
  border-radius: ${({ theme }) =>
    theme.fleetHealth.healthIndicator.innerBorderRadius};
  border: ${({ theme }) => theme.fleetHealth.healthIndicator.innerBorder};
  
}
.fleetHealthText, .imm-text div{
  color:  ${({ theme }) => theme.fleetHealth.healthIndicator.headerColor};
}
.carousel-dtc-code {
  background: ${({ theme }) => theme.fleetHealth.healthIndicator.carouselBg};
  width:  ${({ theme }) => theme.fleetHealth.healthIndicator.width};
  align-item: ${({ theme }) => theme.fleetHealth.healthIndicator.alignItem};
  justify-content:  ${({ theme }) =>
    theme.fleetHealth.healthIndicator.justifyContent};
}
.fleetTextBold {
  font-weight:  ${({ theme }) =>
    theme.fleetHealth.healthIndicator.fleetTextBold};
}
.fleetCode {
  background: ${({ theme }) => theme.fleetHealth.healthIndicator.fleetCodeBg};
  padding: ${({ theme }) => theme.fleetHealth.healthIndicator.fleetCodePadding};
  border-radius: ${({ theme }) =>
    theme.fleetHealth.healthIndicator.borderRadius};
}
.carousel-custom-wrapper {
  flex-wrap: ${({ theme }) => theme.fleetHealth.healthIndicator.flexWrap};
  flex-direction: ${({ theme }) =>
    theme.fleetHealth.healthIndicator.flexDirection};
}
// .severity-block.service-now {
//   background: ${({ theme }) => theme.fleetHealth.healthIndicator.serviceNow};
//   color: ${({ theme }) => theme.fleetHealth.healthIndicator.serviceColor};
// }
.severity-block.service-later {
  background: ${({ theme }) => theme.fleetHealth.healthIndicator.serviceLater};
  color: ${({ theme }) => theme.fleetHealth.healthIndicator.serviceColor};
}
.each-card-width {
  background: ${({ theme }) => theme.fleetHealth.healthIndicator.serviceCard};
  color: ${({ theme }) => theme.fleetHealth.healthIndicator.serviceCardColor};
  border-radius: ${({ theme }) =>
    theme.fleetHealth.healthIndicator.borderRadius};
}
.health-dtc-code {
  background: ${({ theme }) =>
    theme.fleetHealth.healthIndicator.serviceCardCode};
  border-radius: ${({ theme }) =>
    theme.fleetHealth.healthIndicator.borderRadius};
}
#locationShareBeta .table th {
  background-color: ${({ theme }) => theme.reports.tableHeader} !important;
  color:${({ theme }) => theme.reports.tableHeaderFont} !important;
  border: 2px solid ${({ theme }) => theme.reports.tableBorder} !important;
}
#locationShareBeta .table thead tr th {
  border: 2px solid ${({ theme }) => theme.reports.tableBorder} !important;
  text-align: center;
  font-size: 14px !important;
  padding: 0.25rem;
  vertical-align: inherit;
}
#locationShareBeta .table tbody tr {
  background-color: ${({ theme }) => theme.reports.even} !important;
}
#locationShareBeta .table tbody tr:nth-of-type(odd) {
  background-color: ${({ theme }) => theme.reports.odd} !important;
}
#locationShareBeta .table td{
  color: ${({ theme }) => theme.reports.fontTable} !important;
  border: ${({ theme }) => theme.reports.border} !important;
  text-align: center;
}
#locationShareBeta .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background: ${({ theme }) => theme.locationShare.bgColor};
  opacity: ${({ theme }) => theme.locationShare.opactity};
}
.MuiStepper-root{
  background-color: ${({ theme }) => theme.background} !important;
  color: ${({ theme }) => theme.grayColor} !important;
  padding:12px;
}
#generateLiveWindowBeta .select__menu, .expire-select__menu, .select__multi-value{
  background-color: ${({ theme }) => theme.background} !important;
  color: ${({ theme }) => theme.grayColor} !important;
}
#generateLiveWindow .select__menu, .expire-select__menu, .select__multi-value{
  background-color: ${({ theme }) => theme.background} !important;
  color: ${({ theme }) => theme.grayColor} !important;
}
#generateLiveWindow .select__multi-value__label {
  color: ${({ theme }) => theme.liveGrayColor} !important;
}
.select__option--is-focused, .select__option--is-selected, .expire-select__option--is-focused, .expire-select__option--is-selected{
  background-color: ${({ theme }) => theme.selectColor} !important;
  color: ${({ theme }) => theme.liveSelectColor} !important;
}
.input-Field-Live{
  color: ${({ theme }) => theme.liveShare.placeholderText.color} !important;
}
.MuiStepLabel-label.MuiStepLabel-completed{
  color: ${({ theme }) => theme.liveShare.labelName.color} !important;
}
.geo-button{
  color: ${({ theme }) => theme.liveShare.liveShareBtn.color} !important;
  background: ${({ theme }) => theme.liveShare.liveShareBtn.bgColor} !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
  background: ${({ theme }) =>
    theme.liveShare.historyToggle.bgColor} !important;
}

.labelValue{
  color: red !important;
}
.profTab {
  background: ${({ theme }) => theme.profTab.bgColor};
  box-shadow: ${({ theme }) => theme.profTab.boxShadow};
}

.main-wrapper-beta{
  background: ${({ theme }) =>
    theme.betaAccessControl.betaAccessMainWrapper.bgColor};
}
.beta-container{
  background: ${({ theme }) => theme.betaAccessControl.betaContainer.bgColor};
  padding: 15px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.beta-search-input{
  background: ${({ theme }) =>
    theme.betaAccessControl.betaSearchInput.bgColor} !important;
  border: ${({ theme }) =>
    theme.betaAccessControl.betaSearchInput.border} !important;
}
.geo-button-beta, .button-container button{
  background: ${({ theme }) =>
    theme.betaAccessControl.betaAcessSearchBtn.bgColor};
  color: ${({ theme }) => theme.betaAccessControl.betaAcessSearchBtn.color};
  line-height: ${({ theme }) =>
    theme.betaAccessControl.betaAcessSearchBtn.lineHeight};
  border-radius: ${({ theme }) =>
    theme.betaAccessControl.betaAcessSearchBtn.borderRadius};
  border: ${({ theme }) => theme.betaAccessControl.betaAcessSearchBtn.border};
}
.alpha-beta-table th{
  background: ${({ theme }) =>
    theme.betaAccessControl.betaAccessTable.bgColor} !important;
  color: ${({ theme }) =>
    theme.betaAccessControl.betaAccessTable.color} !important;
  border: ${({ theme }) =>
    theme.betaAccessControl.betaAccessTable.border} !important;
}
.alpha-beta-table tr:nth-child(even) td {
  background: ${({ theme }) =>
    theme.betaAccessControl.betaAccessTableEven.bgColor};
  color: ${({ theme }) =>
    theme.betaAccessControl.betaAccessTableEven.color} !important;
  border: ${({ theme }) =>
    theme.betaAccessControl.betaAccessTable.border} !important;
}
.alpha-beta-table tr:nth-child(odd) td {
  background: ${({ theme }) =>
    theme.betaAccessControl.betaAccessTableOdd.bgColor};
  color: ${({ theme }) =>
    theme.betaAccessControl.betaAccessTableOdd.color} !important;
  border: ${({ theme }) =>
    theme.betaAccessControl.betaAccessTable.border} !important;
}
.beta-table-pagination{
  background: ${({ theme }) =>
    theme.betaAccessControl.betaAccessPagination.bgColor} !important;
  color: ${({ theme }) =>
    theme.betaAccessControl.betaAccessPagination.color} !important;
}
.update-btn{
  background: ${({ theme }) =>
    theme.betaAccessControl.betaAcessSearchBtn.bgColor};
  color: ${({ theme }) => theme.betaAccessControl.betaAcessSearchBtn.color};
}
.ant-time-picker-panel-inner{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.timeDropDown.backgroundColor} !important;
  color: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.timeDropDown.color} !important;
  border: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.timeDropDown.border} !important;
}
.ant-time-picker-panel-input{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.timeDropDown.backgroundColor} !important;
  color: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.timeDropDown.color} !important;
}
.ant-time-picker-panel-select {
  .ant-time-picker-panel-select-option-selected{
  background: 
  ${({ theme }) =>
    theme.tripManagementLightTheme.timeDropDownSelected
      .backgroundColor} !important;
}
}
.filterMainDiv .filterBox{
  background: ${({ theme }) => theme.alphaFilter.filterBox.bgColor};
    border: ${({ theme }) => theme.alphaFilter.filterBox.border};
}
.filterMainDiv .filterInput, .filterMainDiv .filterOption {
  color: ${({ theme }) => theme.alphaFilter.filterBox.color};
}
.selectedFilterData .selectedVehicle, .selectedFilterData .showMore .showMoreSelectedVehicles {
  background: ${({ theme }) => theme.selectedFilter.bgColor};
  border: ${({ theme }) => theme.selectedFilter.border};
  color: ${({ theme }) => theme.selectedFilter.color};
}
  .selectedFilterData .showMore .showMoreSelectedVehicles .dropdown-item {
  color: ${({ theme }) => theme.selectedFilter.color};
  }
  .serviceInfoContainer .remainderText, 
    .serviceInfoContainer .remainderText span,
    .serviceInfoRemainder .serviceInfoContainer .serviceContent .serviceRegID,
    .serviceInfoRemainder .serviceInfoContainer .serviceContent .serviceDue {
    color: ${({ theme }) => theme.serviceRemiander.color};
  }
  .serviceSep {
    border: ${({ theme }) => theme.serviceRemiander.border};
    margin: 8px 0;
  }
    .profile-new-wrapper.rootAdmin .menu-dropdown {
    background:${({ theme }) => theme.profilePopup.bgColor};
    }
    .menu-dropdown li:hover .dropdown-container:hover {
    color:${({ theme }) => theme.profilePopup.color};
    }
    .profilePage .table + .hints, .profilePage .profile-count, .profilePage .pagination-dropdown .profile-count span {
    color:${({ theme }) => theme.profilePopup.color} !important;
    } 
    .confirmation-popup-content-restore{
    background:${({ theme }) => theme.profilePopupRestore.bgColor};
     color:${({ theme }) => theme.profilePopupRestore.color};
    }
     .confirmation-popup-content-restore h3{
  color:${({ theme }) => theme.profilePopupRestore.color};
     }
  .confirmation-popup-content-restore button{
   background:${({ theme }) => theme.profilePopupRestoreBtn.bgColor} !important;
  }
   .profilePage .paginationScroller {
    background:${({ theme }) => theme.profilePopup.panelBg};
    min-height: auto;
    height: calc(100vh - 12rem);
    margin: 0 10px 10px;
   }
    .vecihleListPanel, .fluidsGraphPanel {
    background:${({ theme }) => theme.fluidsGraph.bgColor};
    color:${({ theme }) => theme.fluidsGraph.color};
    }
    .fluidsVecihleListPanel {
    background:${({ theme }) => theme.fluidsGraph.panelBg};
    border:${({ theme }) => theme.fluidsGraph.border};
    box-shadow:${({ theme }) => theme.fluidsGraph.boxShadow};
    color:${({ theme }) => theme.fluidsGraph.color};
    }
    .fluidsGraphPanel .highcharts-legend-item text {
    fill:${({ theme }) => theme.fluidsGraph.color} !important;
    }
    .fluidsGraphPanel .highcharts-legend-item.highcharts-legend-item-hidden text {
    fill:${({ theme }) => theme.fluidsGraph.legendDisable} !important;
    }
    .fluidsGraphPanel .vehicleGraph {
      background: ${({ theme }) => theme.fluidsGraph.labelbgColor};
      color: ${({ theme }) => theme.fluidsGraph.labelColor};
    }
    .headerBg, .footerWrapper {
    background: ${({ theme }) => theme.headerBanner.bgColor};
    }
    .headerBg .loginInformation {
    color: ${({ theme }) => theme.headerBanner.color};
    }
    .MuiList-root.MuiMenu-list.MuiList-padding{
      background: 
      ${({ theme }) =>
        theme.findMyTruckStyles.locationShareDropDown.backgroundColor} !important;
      color: 
      ${({ theme }) =>
        theme.findMyTruckStyles.locationShareDropDown.color} !important;
      border: 
      ${({ theme }) =>
        theme.findMyTruckStyles.locationShareDropDown.border} !important;
    }
    .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiTablePagination-menuItem.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button{
      background: 
      ${({ theme }) =>
        theme.findMyTruckStyles.locationShareDropDown.backgroundColor} !important;
      color: 
      ${({ theme }) =>
        theme.findMyTruckStyles.locationShareDropDown.color} !important;
    }
    .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiTablePagination-menuItem.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected{
      background: 
      ${({ theme }) =>
        theme.findMyTruckStyles.locationShareDropDownSelected.backgroundColor} !important;
    }
`;
