/* eslint-disable */

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import FmtGeoButton from './Button';
import FMTList from '../FindMyTruck';
import LiveVehiclePane from '../components/LiveVehiclePane';

const LiveLeftCell = props => {
  const {
    /* Geofence */
    displayTab,
    switchTab,
    isExpand,
    FmtGeoButtonStyle,
    allTrucks,
    disable,
    handleExpand,
    isPlayback,
    isLiveTrackingEnabled,
    handleGeofence,
    handlePlayBack,
    clearBtnSearch,
    viewChange,
    googleMap,
    togglePopupStatus,
    editGeofenceCaller,
    geofenceAllShowCaller,
    switchLoader,
    /* FMTList */
    isGridMap,
    showGroups,
    showRegions,
    defaultSpeed,
    showOverspeed,
    filterDataFMT,
    isInPlayBackStill,
    /* fMtList -> statusFilter */
    running,
    runningData,
    runningDisable,
    idle,
    idleData,
    idleDisable,
    offline,
    offlineData,
    offlineDisable,
    /* FMT Filter Panel */
    showApplyClear,
    applyClicked,
    disableGrp,
    disablerg,
    disableOverspeed,
    /* FMTGroupList */
    checkedGroups,
    groupData,
    fmtGroupList,
    /* FMTRegionList */
    checkedRegions,
    regionData,
    fmtRegionList,
    /* FMT Vehicle List */
    disableSearch,
    checkedVehicles,
    myTruck,
    inputFocused,
    vehicleData,
  } = props;
  console.log(filterDataFMT, 'FFFFF');

  const clientHgt = document.documentElement.clientHeight;
  const divHeight = (clientHgt * 80) / 100;
  const eachHeight = divHeight / 8 - 8;

  return (
    <Col md={isExpand ? 4 : 2} className="mt-4 expnd map-sidebar">
      <div className="p-2">
        {/* <FmtGeoButton
          styleClass={FmtGeoButtonStyle}
          onClickCall={allTrucks}
          title="SHOW ALL TRUCKS"
          disable={disable}
        /> */}
      </div>

      <LiveVehiclePane
        isExpand={isExpand}
        isGridMap={isGridMap}
        clientHgt={clientHgt}
        eachHeight={eachHeight}
        drawRoute1={props.drawRoute1}
        filterDataFMT={filterDataFMT}
        isInPlayBackStill={isInPlayBackStill}
        regIdClickParent={props.regIdClickParent}
        isLiveTrackingEnabled={isLiveTrackingEnabled}
        divHeight={divHeight}
      />
    </Col>
  );
};

export default LiveLeftCell;
