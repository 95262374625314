import {
  DEF_FMT_SEARCH_PARAM,
  DEF_RP_SEARCH_PARAM,
  DEF_NEWFMT_SEARCH_PARAM,
} from '../components/Track-Trace/constants';

const defaultState = {
  searchParamsFMT: {
    ...DEF_FMT_SEARCH_PARAM,
  },
  searchParamsNewFMT: {
    ...DEF_NEWFMT_SEARCH_PARAM,
  },
  searchParamsRP: {
    ...DEF_RP_SEARCH_PARAM,
  },
  filter: {
    filterBy: 'Fleet',
    selVehIds: [],
  },
  filterRp: {
    selVin: '',
    dateType: 'Last 24 hrs',
    maxFromDate: null,
    minFromDate: null,
    maxToDate: null,
    mTime: 0,
    mxTime: 23,
    emTime: 0,
    emxTime: 0,
    fromDate: null,
    toDate: null,
  },
  defaultVins: [],
  defaultVehIds: [],
  routeReplaySel: '',
  alertNotificationData: {},
  alertEnabledData: {},
};

function trackTraceReducer(state = defaultState, action) {
  switch (action.type) {
    case 'API_FLTR_FMT':
      return {
        ...state,
        searchParamsNewFMT: {
          ...action.payload,
        },
      };
    case 'API_FLTR_RP':
      return {
        ...state,
        searchParamsRP: {
          ...state.searchParamsRP,
          vins: action.payload.vins,
          vehicleIds: action.payload.vehicleIds,
          fromTsInIST: action.payload.fromTsInIST,
          toTsInIST: action.payload.toTsInIST,
        },
      };
    case 'UPD_API_FLTR_FMT':
      return {
        ...state,
        defaultVehIds: action.payload.defaultVehIds,
        searchParamsNewFMT: {
          vehicleIds: action.payload.vehicleIds,
        },
      };
    case 'FLTR_UI_STATE_TT':
      return {
        ...state,
        filter: action.payload,
      };
    case 'FLTR_UI_STATE_RP':
      return {
        ...state,
        filterRp: action.payload,
      };
    case 'ROUTE_REPLAY_SELECTED':
      return {
        ...state,
        routeReplaySel: action.payload,
      };
    case 'UPDATE_ALERT_COUNT':
      return {
        ...state,
        alertNotificationData: action.payload,
      };
    case 'UPDATE_ALERT_ENABLED':
      return {
        ...state,
        alertEnabledData: action.payload,
      };
    default:
      return state;
  }
}

export default trackTraceReducer;
