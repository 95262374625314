/* eslint-disable */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import toastr from 'toastr';
import isEqual from 'react-fast-compare';
import ReactTooltip from 'react-tooltip';
import Authorization from '../../Helpers/Authorization';
import ThemeSwitcher from '../UI/ThemeSwitcher';
import { getUserDetailForMyAdmin } from '../Profiles/MyProfile/MyProfileAction';
// import Logo from '../../assets/images/header/Logo_Truckonnect.png';
import LogoWhite from '../../assets/images/tk_logo_white.png';
import LogoBlue from '../../assets/images/tk_logo_blue.png';
import profileDarkImg from '../../assets/images/header/profile-dark.png';
import profileLightImg from '../../assets/images/header/profile-light.png';
import logoutDarkImg from '../../assets/images/header/logout-dark.png';
import logoutLightImg from '../../assets/images/header/logout-light.png';
import BetaSwitcher from '../UI/BetaSwitcher';
import { getVehicleDropdownData } from '../OwnerSuite/DropdownAction/DropdownActions';
import Env from '../../Helpers/env';
import AlertNotifications from '../UI/AlertNotifications';
import { filterEnableAlerts } from '../Alerts/constants';
import SwitchProfileImg from '../../assets/images/login/Switchprofile.svg';
import themeSelected from '../Common/Context';

class Header extends React.Component {
  static contextType = themeSelected;
  constructor(props) {
    super(props);
    this.lightThemeUnAvailable =
      JSON.parse(localStorage.getItem('alphaUser')) === true &&
      JSON.parse(localStorage.getItem('betaUser')) === false;

    this.state = {
      toggle: false,
      betaToggle: false,
      firstName: '',
      showToggle: true,
      alertNotificationDetails: [],
      closeAlertPopup: false,
    };

    this.handleClickToggle = this.handleClickToggle.bind(this);
    this.handleBetaToggle = this.handleBetaToggle.bind(this);
  }

  componentDidMount() {
    sessionStorage.removeItem('filterState');
    sessionStorage.removeItem('lastSavedFmtData');
    sessionStorage.removeItem('filterStateClickedFMT');
    sessionStorage.removeItem('rpFilterState');
    sessionStorage.removeItem('lastSavedRpData');
    sessionStorage.removeItem('filterStateClickedRp');
    sessionStorage.removeItem('driverScoreState');
    sessionStorage.removeItem('lastSavedDriverScoreData');
    sessionStorage.removeItem('driverScoreStateClicked');
    sessionStorage.removeItem('driverScoreReportState');
    sessionStorage.removeItem('lastSaveddriverScoreReportData');
    sessionStorage.removeItem('driverScoreReportClicked');
    sessionStorage.removeItem('alertFilterState');
    sessionStorage.removeItem('lastSavedAlertData');
    sessionStorage.removeItem('alertStateClicked');
    sessionStorage.removeItem('alertReportFilterState');
    sessionStorage.removeItem('lastSavedAlertReportData');
    sessionStorage.removeItem('alertReportStateClicked');
    sessionStorage.removeItem('reportFilterState');
    sessionStorage.removeItem('lastSavedReportData');
    sessionStorage.removeItem('reportStateClicked');
    sessionStorage.removeItem('instantReportFilterState');
    sessionStorage.removeItem('lastSavedInstantReportData');
    sessionStorage.removeItem('instantReportStateClicked');
    sessionStorage.removeItem('autotripReportFilterState');
    sessionStorage.removeItem('lastSavedAutotripReportData');
    sessionStorage.removeItem('autotripReportStateClicked');
    sessionStorage.removeItem('fluidsFilterState');
    sessionStorage.removeItem('lastSavedFluidsData');
    sessionStorage.removeItem('fluidsStateClicked');
    sessionStorage.removeItem('huhtohubReportFilterState');
    sessionStorage.removeItem('lastSavedHubtohubReportData');
    sessionStorage.removeItem('hubtohubReportStateClicked');
    sessionStorage.removeItem('serviceDueReportFilterState');
    sessionStorage.removeItem('lastSavedServiceDueReportData');
    sessionStorage.removeItem('servicedueReportStateClicked');

    const userRole = Authorization.getAuthUserRole();
    const alphaAccess = Authorization.getAlphaAccess();
    const betaAccess = Authorization.getBetaAccess();
    const isCssupportPath = window.location.pathname === '/cssupport';
  if (isCssupportPath) {
    return;
  }
    if (userRole === 'ROOTADMIN' || !alphaAccess || !betaAccess) {
      this.setState({ showToggle: false });
    }
    if (localStorage.getItem('firstName') !== null) {
      const firstName = localStorage.getItem('firstName');
      this.setState({ firstName });
    } else {
      const obj = { userId: Authorization.getAuthUserId() };
      this.props.dispatch(getUserDetailForMyAdmin(obj));
    }
    const { alertEnabledData } = this.props.alertSettingData;
    if (alertEnabledData && Object.keys(alertEnabledData).length === 0) {
      if (userRole !== 'SUPPORTADMIN') {
        this.getEnabledAlerts();
      }
    }
  }
  getEnabledAlerts = async () => {
    let token = JSON.parse(localStorage.getItem('auth_user'));
    const obj = { userId: Authorization.getAuthUserId() };
    const body = JSON.stringify({ userId: obj.userId });
    const baseUrl = Env.env.REACT_APP_BACKEND_BASE_URL_V3;
    const url = `${baseUrl}/settings/${Env.env.REACT_APP_BACKEND_TK_API_VERSION}/retrieve`;
    
    const userRole = Authorization.getAuthUserRole();
    
    const headers = {
        'Content-Type': 'application/json',
        Authorization: token.token,
        key: Env.env.REACT_APP_API_KEY,
    };
    
    if (userRole === 'CSSUPPORT') {
        const csSupportRole = localStorage.getItem('csSupportRequestedLoggedInRole');
        const csSupportUserId = localStorage.getItem('csSupportRequestedLoggedInUserId');
        
        if (csSupportRole && csSupportUserId) {
            headers.csSupportRequestedLoggedInRole = csSupportRole;
            headers.csSupportRequestedLoggedInUserId = csSupportUserId;
        }
    }
    
    let response1 = await fetch(url, {
        method: 'POST',
        headers,
        body,
    });
    
    let data = await response1.json();
    this.props.dispatch({
        type: 'UPDATE_ALERT_ENABLED',
        payload: data,
    });
    
    const { response } = this.props.getVehicleDropdownData;

    if (userRole === 'CUSTOMER' || userRole === 'CUSTOMERADMIN') {
        const temp = [];
        if (response && response.status === 200) {
            response.vehicles.map(e => {
                temp.push(e.vehicleId);
            });
            this.setState({
                vehicles1: this.props.getVehicleDropdownData.response.vehicles,
                allVehicleIds: temp,
                truckPerformanceLoading: false,
            });
            const alertObj = {
                groupNames: [],
                fromTsInIST: moment().subtract(5, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                toTsInIST: moment().format('YYYY-MM-DD HH:mm:ss'),
                reportNames: [
                    'OVERSPEED',
                    'NEUTRALDRIVING',
                    'IDLING',
                    'STOPPAGE',
                    'FUELFILL',
                    'FUELDROP',
                    'ADBLUELOW',
                    'ADBLUEFAULT',
                    'ADBLUEZONE',
                    'ENGINEOVERRUN',
                    'GEOFENCEINOUT',
                ],
                vehicleIds: temp,
            };
            this.getAllAlertNotification(alertObj);
        } else {
            this.props.dispatch(getVehicleDropdownData());
        }
        this.interval = setInterval(() => {
            this.getAllAlertNotification();
        }, 300000);
    }
};


  componentDidUpdate(prevProps) {
    // get response with based on id
    if (
      !isEqual(
        prevProps.getUserDetailForMyAdmin.response,
        this.props.getUserDetailForMyAdmin.response
      )
    ) {
      const authUser = JSON.parse(localStorage.getItem('auth_user'));
      const { response } = this.props.getUserDetailForMyAdmin;

      if (authUser.id === response.userId) {
        localStorage.setItem('firstName', response.firstName);
        this.setState({ firstName: response.firstName });
      }
    }

    if (
      !isEqual(
        prevProps.getVehicleDropdownData.response,
        this.props.getVehicleDropdownData.response
      )
    ) {
      if (
        !_.isEmpty(
          this.props.getVehicleDropdownData.response &&
            this.props.getVehicleDropdownData.response.vehicles
        )
      ) {
        const { alertEnabledData } = this.props.alertSettingData;
        let enabledAlerts = [];
        if (alertEnabledData && Object.keys(alertEnabledData).length > 0) {
          const alerts = alertEnabledData.alertSettings;
          enabledAlerts = filterEnableAlerts(alerts);
        }
        const temp = [];
        this.props.getVehicleDropdownData.response.vehicles.map(e => {
          temp.push(e.vehicleId);
        });
        this.setState({
          vehicles1: this.props.getVehicleDropdownData.response.vehicles,
          allVehicleIds: temp,
          truckPerformanceLoading: false,
        });
        const alertObj = {
          groupNames: [],
          fromTsInIST: moment()
            .subtract(5, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss'),
          toTsInIST: moment().format('YYYY-MM-DD HH:mm:ss'),
          reportNames: enabledAlerts,
          vehicleIds: temp,
        };
        const userRole = Authorization.getAuthUserRole();
        if (userRole === 'CUSTOMER' || userRole === 'CUSTOMERADMIN') {
          this.getAllAlertNotification(alertObj);
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getAllAlertNotification = async params => {
    const { vehicles } = this.props.getVehicleDropdownData.response;
    let newParams = params;
    const temp = [];
    if (vehicles && vehicles.length > 0) {
      vehicles.map(e => {
        temp.push(e.vehicleId);
      });
    }
    const { alertEnabledData } = this.props.alertSettingData;
    let enabledAlerts = [];
    if (alertEnabledData && Object.keys(alertEnabledData).length > 0) {
      const alerts = alertEnabledData.alertSettings;
      enabledAlerts = filterEnableAlerts(alerts);
    }
    const alertObj = {
      groupNames: [],
      fromTsInIST: moment()
        .subtract(5, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss'),
      toTsInIST: moment().format('YYYY-MM-DD HH:mm:ss'),
      reportNames: enabledAlerts,
      vehicleIds: temp,
    };
    let token = JSON.parse(localStorage.getItem('auth_user'));
    const { roles } = JSON.parse(localStorage.getItem('auth_user'));

    const headers = {
      'Content-Type': 'application/json',
      Authorization: token.token,
      key: Env.env.REACT_APP_API_KEY,
    };

    if (roles[0] === 'CSSUPPORT') {
      const userId = localStorage.getItem('csSupportRequestedLoggedInUserId');
      const userRole = localStorage.getItem('csSupportRequestedLoggedInRole');

      headers.csSupportRequestedLoggedInUserId = userId;
      headers.csSupportRequestedLoggedInRole = userRole;
    }

    const baseUrl = Env.env.REACT_APP_BACKEND_BASE_URL_V3;
    const url = `${baseUrl}/alerts/${Env.env.REACT_APP_BACKEND_TK_API_VERSION}/getAlertNotificationDetails`;
    newParams = temp.length > 0 ? alertObj : params;
    let response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(newParams),
    });
    let data = await response.json();
    this.props.dispatch({
      type: 'UPDATE_ALERT_COUNT',
      payload: data,
    });
    if (data && Object.keys(data).length > 0) {
      const alerts = Object.keys(data)
        .reduce((acc, key) => {
          if (data[key].length > 0) {
            acc.push(...data[key].map(details => ({ type: key, details })));
          }
          return acc;
        }, [])
        .sort((a, b) => moment(b.details.Date) - moment(a.details.Date));
      this.setState({
        alertNotificationDetails: alerts,
        closeAlertPopup: true,
      });
    } else {
      this.setState({ alertNotificationDetails: [], closeAlertPopup: false });
    }
  };

  handleClickToggle = () => {
    this.setState(prevstate => ({
      toggle: !prevstate.toggle,
    }));
  };

  handleBetaToggle = () => {
    this.setState(prevstate => ({
      betaToggle: !prevstate.betaToggle,
    }));
  };

  closeAlertWindow = () => {
    this.setState({ closeAlertPopup: false });
  };

  handleIamLogout = () => {
    const id_token = localStorage.getItem('id_token');
    const logout_url = `${Env.env.REACT_APP_IAM_BASE_URL}/logout?id_token_hint=${id_token}&post_logout_redirect_uri=${Env.env.REACT_APP_IAM_REDIRECT_URI}`;
    Authorization.logout();
    window.location = logout_url;
  };

  handleSwitchProfile = () => {
    localStorage.removeItem('csSupportRequestedLoggedInUserId');
    localStorage.removeItem('csSupportRequestedLoggedInRole');
    localStorage.removeItem('csSupportRequestedLoggedInUserName');

    window.location.href = '/cssupport';
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  render() {
    const { firstName } = this.state;
    const { theme } = this.context;
    const userRole = Authorization.getAuthUserRole();
    const isCssupportPath = window.location.pathname === '/cssupport';
    
    return (
      <>
        <div className="full-height">
          <div className="d-flex justify-content-between p-2 headerBg">
            <img src={theme === 'dark' ? LogoWhite : LogoBlue} className="header-logo" alt="logo" />
            <div className="loginInformation colorwhite d-flex align-items-center">
              {this.state.showToggle ? <BetaSwitcher /> : null}
              {userRole === 'CSSUPPORT' && !isCssupportPath && (
                <Link
  to="/cssupport"
  className="mr-2"
  onClick={(e) => {
    e.preventDefault(); // Prevent default Link behavior
    this.handleSwitchProfile(); // Call the switch profile method
  }}
>
  <button className="support-admin-button">
    <img
      src={SwitchProfileImg}
      alt="Support Admin"
      className="mr-1"
    />
    SWITCH PROFILE
  </button>
</Link>

              )}
              {this.lightThemeUnAvailable ? null : <ThemeSwitcher />}
    
              {`Welcome back, ${firstName ? firstName.toLowerCase() : ''}  !`}
              {!isCssupportPath && (
                <Link to="/profile" className="ml-2">
                  <img
                    src={theme === 'dark' ? profileDarkImg : profileLightImg}
                    style={{width: '22px'}}
                    alt="profile"
                    data-for="ProfileId"
                    data-tip="Profile"
                  />
                </Link>
              )}
            </div>
            <div
              className="d-flex align-items-center"
              style={{ color: 'white', zoom: '0.8' }}
            >
              <div
                role="presentation"
                className="text-white p-1 align-self-center cursor-pointer d-flex"
                onClick={() => this.handleIamLogout()}
              >
                <img className="mr-1" src={theme === 'dark' ? logoutDarkImg : logoutLightImg } alt="logout" />
              </div>
            </div>
            <ReactTooltip
              backgroundColor="grey"
              textColor="white"
              place="left"
              id="ProfileId"
            />
          </div>
        </div>
        {this.state.closeAlertPopup &&
          this.state.alertNotificationDetails.length > 0 && (
            <AlertNotifications
              alerts={[this.state.alertNotificationDetails[0]]}
              allAlerts={this.state.alertNotificationDetails}
              onClose={this.closeAlertWindow}
              closePopup={this.closeAlertWindow}
            />
          )}
      </>
    );
  }  
  
  
}
export default connect(state => ({
  getUserDetailForMyAdmin: state.getUserDetail,
  themeSelected: state.uiState.themeSelected,
  getVehicleDropdownData: state.getVehicleDropdownData,
  alertSettingData: state.trackTraceState,
}))(Header);
