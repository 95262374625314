/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-dupe-keys */
import React from 'react';
import Switch from '@material-ui/core/Switch';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '110px',
    height: '30px',
    padding: '0px',
    cursor: 'pointer',
  },
  switchBase: {
    color: '#818181',
    padding: '0px',
    left: '43px',
    transform: 'translateX(-41px) !important',
    '&$checked': {
      height: '30px',
      width: '24.4px',
      '& + $track': {
        backgroundColor: '#23bf58',
        border: '2px solid black',
      },
    },
  },
  thumb: {
    color: 'white',
    width: '25px',
    height: '29px',
  },
  track: {
    borderRadius: '20px',
    backgroundColor: '#818181',
    opacity: '1 !important',
    border: '2px solid white',
    '&:after, &:before': {
      color: '#ffffff',
      fontWeight: 'bolder',
      textShadow: '0 0 10px rgba(0, 0, 0, 1)',
      fontSize: '18px',
      position: 'absolute',
      top: '3px',
      letterSpacing: '1.5px',
    },
    '&:before': {
      content: "'BETA'",
      right: '35px',
    },
  },
  checked: {
    color: '#23bf58 !important',
    transform: 'translateX(40px) !important',
  },
});

const BetaSwitcher = () => {
  const classes = useStyles();
  const betaAccess = JSON.parse(localStorage.getItem('alphaUser'))
    && JSON.parse(localStorage.getItem('betaUser'));
  const { uiSelected } = useSelector(state => state.uiState);
  const dispatch = useDispatch();

  const toggleBeta = () => {
    // eslint-disable-next-line no-nested-ternary
    const beta = localStorage.getItem('uiType') === 'alpha'
      ? 'beta'
      : localStorage.getItem('uiType') === null
        ? 'beta'
        : 'alpha';
    localStorage.setItem('uiType', beta);
    dispatch({ type: 'TOGGLE_BETA', payload: beta });
  };

  return betaAccess ? (
    <span
      className="mr-2"
      onClick={toggleBeta}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          toggleBeta();
        }
      }}
    >
      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        checked={uiSelected === 'beta'}
        onChange={toggleBeta}
        name="betaToggle"
        inputProps={{ 'aria-label': 'BETA' }}
      />
    </span>
  ) : null;
};

export default BetaSwitcher;
