import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Container extends Component {
  componentWillMount() {
    this.unlisten = this.props.history.listen(location => {
      if (
        !(
          location.pathname === '/alerts'
          || location.pathname === '/reportsv2'
          || location.pathname === '/driving-analysisv2'
          || location.pathname === '/track-trace'
          || location.pathname === '/dashboard'
          || location.pathname === '/trip-management'
          || location.pathname === 'liveTrack'
        )
      ) {
        this.props.enterDarkSide();
      } else {
        this.props.enterLightSide();
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

const AppContainer = withRouter(Container);

export default AppContainer;
