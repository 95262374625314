import { DEF_SEARCH_PARAM } from '../components/TripManagement/constants';

const defaultState = {
  searchParams: {
    ...DEF_SEARCH_PARAM,
  },
  filter: {
    filterBy: 'Fleet',
    selVins: [],
    fromDate: '',
    toDate: '',
  },
  currentSelectedTab: 'LIVE',
  disableDownloadBtns: true,
  defaultVins: [],
  tripIdList: [],
};

function tripManagmentReducer(state = defaultState, action) {
  switch (action.type) {
    case 'API_FLTR_TM':
      return {
        ...state,
        searchParams: action.payload,
      };
    case 'UPD_API_FLTR_TM':
      return {
        ...state,
        defaultVins: action.payload.defaultVins,
        searchParams: {
          ...state.searchParams,
          vehicles: action.payload.vins,
        },
      };
    case 'FLTR_UI_STATE_TM':
      return {
        ...state,
        filter: action.payload,
      };
    case 'TM_TOGGLE_DWNLD_BTN':
      return { ...state, disableDownloadBtns: action.payload };
    case 'TM_TAB_SELECTED':
      return { ...state, currentSelectedTab: action.payload };
    case 'TM_SELECTED_TRIPS_ADD':
      return {
        ...state,
        tripIdList: [...state.tripIdList, action.payload],
      };
    case 'TM_SELECTED_TRIPS_REMOVE':
      return {
        ...state,
        tripIdList: state.tripIdList.filter(item => item !== action.payload),
      };
    case 'TM_SELECTED_TRIPS_SELECT_ALL':
      return {
        ...state,
        tripIdList: [...new Set([...state.tripIdList, action.payload])],
      };
    case 'TM_SELECTED_TRIPS_REMOVE_ALL':
      return {
        ...state,
        tripIdList: [],
      };
    default:
      return state;
  }
}

export default tripManagmentReducer;
