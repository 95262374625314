/* eslint-disable no-console */
/* eslint-disable arrow-parens */
/* eslint-disable max-classes-per-file */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import Authorization from '../../Helpers/Authorization';
import {
  AdminNavLinks,
  // NavLinks,
  SuperAdminNavLinks,
  uatNavLinks,
  alphaNavLinks,
} from '../../Helpers/Constants';

const StyledSideNav = styled.div`
  position: fixed; /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  height: 100%;
  width: 3.5rem; /* Set the width of the sidebar */
  z-index: 4; /* Stay on top of everything */
  background-color: #212a54;
  padding-top: 0.5rem;
  transition: 0.3s;
`;

class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePath: window.location.pathname,
      items: uatNavLinks,
    };
  }

  componentDidMount() {
    const userRole = Authorization.getAuthUserRole();
    // const userId = Authorization.getAuthUserId();
    const alphaAccess = Authorization.getAlphaAccess();
    const betaAccess = Authorization.getBetaAccess();

    // For UAT Testing purposes only
    // if (userId === 4124847) {
    //   this.setState({ items: uatNavLinks });
    // }

    if (userRole === 'ROOTADMIN') {
      this.setState({ items: AdminNavLinks });
    } else if (!alphaAccess && betaAccess) {
      this.setState({ items: uatNavLinks });
    } else if (this.props.uiType === 'beta') {
      this.setState({ items: uatNavLinks });
    } else if (this.props.uiType === 'alpha') {
      this.setState({ items: alphaNavLinks });
    }

    if (userRole === 'SUPERADMIN') {
      this.setState({ items: SuperAdminNavLinks });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.uiType, this.props.uiType)) {
      if (this.props.uiType === 'beta') {
        this.setState({ items: uatNavLinks });
        this.props.history.push('/owner-suite');
      } else {
        this.setState({ items: alphaNavLinks });
        this.props.history.push('/owner-suite');
      }
    }
  }

  onItemClick = path => {
    this.setState({ activePath: path });
  };

  render() {
    const { items, activePath } = this.state;

    return (
      <StyledSideNav>
        {items.map(item => (
          <NavItem
            path={item.path}
            name={item.name}
            css={item.css}
            icon={item.icon}
            onItemClick={this.onItemClick}
            active={item.path === activePath}
            key={item.key}
          />
        ))}
      </StyledSideNav>
    );
  }
}

const RouterSideNav = withRouter(SideNav);

const StyledNavItem = styled.div`
  height: 45px;
  width: 3.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
  padding: 0.7rem 0.5rem 0rem 1rem;
  line-height: 4rem;
  border-right: ${props => (props.active ? 'solid #1698cb' : '')};

  @media only screen and (min-width: 540px) and (max-width: 720px) and (orientation: landscape) {
    height: 40px;
    padding-top: 10px;
  }
`;

class NavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      show: false,
    };
  }

  handleClick = () => {
    const { path, onItemClick } = this.props;
    onItemClick(path);
  };

  hoverFunction = name => {
    this.setState({ name, show: true });
  };

  mouseOut = () => {
    this.setState({ show: false });
  };

  render() {
    const { active } = this.props;

    return (
      <>
        <div className="d-flex wrapper-icon">
          <StyledNavItem active={active}>
            <Link
              to={this.props.path}
              className={this.props.css}
              onClick={this.handleClick}
              style={{ zoom: '0.8' }}
            >
              <div
                className={this.props.icon}
                onMouseLeave={this.mouseOut}
                onMouseEnter={() => this.hoverFunction(this.props.name)}
              />
            </Link>
          </StyledNavItem>
          {this.state.show && (
            <div className="hover-bar">
              <p className="text-hover">{this.state.name}</p>
            </div>
          )}
        </div>
      </>
    );
  }
}

function SideBar(props) {
  return <RouterSideNav uiType={props.uiType} />;
}

export default SideBar;
