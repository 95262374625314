/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export const DEF_FMT_SEARCH_PARAM = {
  vins: [],
  groupNames: [],
};

export const DEF_NEWFMT_SEARCH_PARAM = {
  vehicleIds: [],
  groupNames: [],
};

export const DEF_RP_SEARCH_PARAM = {
  vins: '',
  vehicleIds: '',
  fromTsInIST: moment(new Date())
    .subtract(24, 'hours')
    .format('YYYY-MM-DD HH:mm:ss'),
  toTsInIST: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  groupNames: [],
};

export const geoFenceTag = [
  { id: 'addTag', name: 'AddTag' },
  { id: 'loading', name: 'Loading' },
  { id: 'unloading', name: 'Unloading' },
  { id: 'parking', name: 'Parking' },
];

// Filter by dropdown
export const filterBy = [
  { id: 'fleet', name: 'Fleet' },
  { id: 'vehicle', name: 'Vehicle' },
  { id: 'group', name: 'Group' },
];

export const fenceOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  editable: !0,
  zIndex: 1,
};
