/* eslint-disable */
import React, { useState } from 'react';
import AlertNotificationPopup from './AlertNotificationPopup';

const AlertNotifications = ({ alerts, allAlerts, closePopup }) => {
  const [showAllAlerts, setShowAllAlerts] = useState(false);

  const toggleShowAll = () => {
    setShowAllAlerts(!showAllAlerts);
  };

  return (
    <>
      {alerts.map((alert, index) => (
        <AlertNotificationPopup
          key={index}
          alert={alert}
          closePopup={closePopup}
          showAllAlerts={showAllAlerts}
          toggleShowAll={toggleShowAll}
          allAlerts={allAlerts}
          history={history}
        />
      ))}
    </>
  );
};

export default AlertNotifications;
