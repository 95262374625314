import {
  PENDING,
  FULFILLED,
  REJECTED,
  serviceUrl,
  dueSoon,
  overdue,
  serviceCount,
  showAll,
  getVehicleIds,
  vehicleData,
} from '../../../Helpers/Constants';
import Authorization from '../../../Helpers/Authorization';

const userId = Authorization.getAuthUserId();

export function getVehicleDropdownData() {
  return {
    types: [
      `GET_VEHICLE_DROPDOWN_DATA_${PENDING}`,
      `GET_VEHICLE_DROPDOWN_DATA_${FULFILLED}`,
      `GET_VEHICLE_DROPDOWN_DATA_${REJECTED}`,
    ],
    method: 'GET',
    url: `${vehicleData}${userId}`,
    payload: {},
  };
}

export function getSearchVehicleIds(data) {
  return {
    types: [
      `GET_VEHICLE_SEARCH_IDS_${PENDING}`,
      `GET_VEHICLE_SEARCH_IDS_${FULFILLED}`,
      `GET_VEHICLE_SEARCH_IDS_${REJECTED}`,
    ],
    method: 'POST',
    url: getVehicleIds,

    payload: {},
    data,
  };
}

export function showAllTrucksDashboard() {
  return {
    types: [
      `SHOW_ALL_TRUCKS_DASHBOARD_${PENDING}`,
      `SHOW_ALL_TRUCKS_DASHBOARD_${FULFILLED}`,
      `SHOW_ALL_TRUCKS_DASHBOARD_${REJECTED}`,
    ],
    method: 'GET',
    url: showAll,
    payload: {},
  };
}

export function getServiceRemainderCount(data) {
  return {
    types: [
      `GET_SERVICE_REMAINDER_COUNT_${PENDING}`,
      `GET_SERVICE_REMAINDER_COUNT_${FULFILLED}`,
      `GET_SERVICE_REMAINDER_COUNT_${REJECTED}`,
    ],
    method: 'POST',
    url: serviceCount,
    payload: {},
    data,
  };
}

export function selectServiceType(data) {
  return {
    types: [
      `GET_SERVICE_TYPE_${PENDING}`,
      `GET_SERVICE_TYPE_${FULFILLED}`,
      `GET_SERVICE_TYPE_${REJECTED}`,
    ],
    method: 'POST',
    url: overdue,
    payload: {},
    data,
  };
}

export function selectServiceForDueSoon(data) {
  return {
    types: [
      `GET_SERVICE_DueSoon_${PENDING}`,
      `GET_SERVICE_DueSoon_${FULFILLED}`,
      `GET_SERVICE_DueSoon_${REJECTED}`,
    ],
    method: 'POST',
    url: dueSoon,
    payload: {},
    data,
  };
}

export function serviceDone(data) {
  return {
    types: [
      `GET_SERVICE_DONE_${PENDING}`,
      `GET_SERVICE_DONE_${FULFILLED}`,
      `GET_SERVICE_DONE_${REJECTED}`,
    ],
    method: 'PUT',
    url: serviceUrl,
    payload: {},
    data,
  };
}
