import { DEF_SEARCH_PARAM } from '../components/Dashboard/constants';

const defaultState = {
  searchParams: {
    ...DEF_SEARCH_PARAM,
  },
  filter: {
    filterBy: 'Fleet',
    selVins: [],
    dateType: 'Last 7 Days',
    fromDate: '',
    toDate: '',
  },
  defaultVins: [],
};

function dashboardReducer(state = defaultState, action) {
  switch (action.type) {
    case 'API_FLTR_DB':
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          vins: action.payload.vins,
          fromTsInIST: action.payload.fromTsInIST,
          toTsInIST: action.payload.toTsInIST,
          groupNames: action.payload.groupNames,
        },
      };
    case 'UPD_API_FLTR_DB':
      return {
        ...state,
        defaultVins: action.payload.defaultVins,
        searchParams: {
          ...state.searchParams,
          vins: action.payload.vins,
        },
      };
    case 'FLTR_UI_STATE_DB':
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
}

export default dashboardReducer;
