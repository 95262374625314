// import loadable from '@loadable/component';
import Loadable from 'react-loadable';

import ProfileSideBarMenu from './components/Profiles/ProfileSideBarMenu';
import ReportsSideBarMenu from './components/Reports/ReportsSideBarMenu';
import UserReportsSideBarMenu from './components/Profiles/UserProfileSidebarMenu';

import Loader from './components/Common/loader';
// import myFleetSideMenu, { sideBarMenu1 } from './components/MyFleet/sideBarMenu';
import Authorization from './Helpers/Authorization';

// site title
export const siteTitle = 'Daimler Trucks';
export const siteMetaDescription = 'Daimler Trucks';

export const setTitle = title => {
  document.title = title || siteTitle;
};

export const setMetaDescription = description => {
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', description || siteMetaDescription);
};
const userRole = Authorization.getAuthUserRole();

export const ROUTE = [
  // Login
  {
    private: false,
    exact: true,
    id: 1,
    path: '/',
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    sideBar: false,
    component: Loadable({
      loader: () => import('./components/Login'),
      loading: Loader,
    }),
    beta: false,
  },
  // Login
  {
    private: false,
    exact: true,
    id: 2,
    path: '/login',
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Login'),
      loading: Loader,
    }),
    beta: false,
  },
  // Forgot-Password
  {
    private: false,
    path: '/forgot-password',
    id: 3,
    sideBar: false,
    exact: true,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Forgetpassword'),
      loading: Loader,
    }),
    beta: false,
  },
  // Driver-Profile
  {
    private: true,
    path: '/profiles/driver',
    id: 4,
    sideBar: true,
    exact: true,
    sideBarMenu:
      userRole === 'ROOTADMIN' ? ProfileSideBarMenu : UserReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Profiles/DriverProfile'),
      loading: Loader,
    }),
    beta: false,
  },
  // Vehicle-Profile
  {
    private: true,
    path: '/profiles/vehicle',
    id: 5,
    sideBar: true,
    exact: true,
    sideBarMenu:
      userRole === 'ROOTADMIN' ? ProfileSideBarMenu : UserReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Profiles/VehicleProfile'),
      loading: Loader,
    }),
    beta: false,
  },
  // Dealer-Profile
  {
    private: true,
    path: '/profiles/dealer',
    id: 6,
    sideBar: true,
    exact: true,
    sideBarMenu:
      userRole === 'ROOTADMIN' ? ProfileSideBarMenu : UserReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Profiles/DealerProfile'),
      loading: Loader,
    }),
    beta: false,
  },
  // Profiles
  {
    private: true,
    exact: true,
    path: '/profiles',
    id: 7,
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Profiles'),
      loading: Loader,
    }),
    beta: false,
  },
  // Owner-Suites
  // {
  //   private: true,
  //   path: "/owner-suite",
  //   sideBar: false,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import(`./components/OwnerSuite/index`),
  //     loading: Loader
  //   })
  // },
  {
    private: true,
    path: '/owner-suite',
    id: 8,
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/OwnerSuite/index'),
      loading: Loader,
    }),
    beta: false,
  },
  {
    private: true,
    id: 9,
    path: '/cssupport',
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    sideBar: false,
    component: Loadable({
      loader: () => import('./components/SupportAdminLogin'),
      loading: Loader,
    }),
    beta: false,
  },
  {
    private: true,
    path: '/dashboard',
    id: 32,
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Dashboard/index'),
      loading: Loader,
    }),
  },
  {
    private: true,
    path: '/trip-management',
    id: 33,
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/TripManagement/index'),
      loading: Loader,
    }),
    beta: false,
  },
  // my-fleet
  // {
  //   private: true,
  //   exact: true,
  //   path: '/my-fleet',
  //   id: 9,
  //   sideBar: true,
  //   // sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription,
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/MyFleet'),
  //     loading: Loader,
  //   }),
  //   beta: false,
  // },
  {
    private: true,
    exact: true,
    path: '/track-trace',
    id: 31,
    sideBar: true,
    // sideBarMenu: myFleetSideMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Track-Trace'),
      loading: Loader,
    }),
    beta: true,
  },
  // truck-utilization
  // {
  //   private: true,
  //   exact: true,
  //   path: '/truck-utilization',
  //   sideBar: true,
  //   sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/TruckUtilization'),
  //     loading: Loader
  //   })
  // },
  // My-Fleet sidemenu

  // {
  //   private: true,
  //   exact: true,
  //   path: '/driving-analysis',
  //   id: 10,
  //   sideBar: false,
  //   // sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription,
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/DrivingAnalysis'),
  //     loading: Loader,
  //   }),
  //   beta: false,
  // },

  {
    private: true,
    exact: true,
    path: '/profile',
    id: 11,
    sideBar: false,
    // sideBarMenu: myFleetSideMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Profiles'),
      loading: Loader,
    }),
    beta: false,
  },

  {
    private: true,
    exact: true,
    path: '/settings',
    sideBar: false,
    id: 12,
    // sideBarMenu: myFleetSideMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Settings'),
      loading: Loader,
    }),
    beta: false,
  },
  // {
  //   private: true,
  //   exact: true,
  //   path: '/driver-summary',
  //   sideBar: false,
  //   // sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/DrivingAnalysis/DriverSummary'),
  //     loading: Loader
  //   })
  // },
  // {
  //   private: true,
  //   path: '/driver-detail',
  //   exact: true,
  //   sideBar: false,
  //   // sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/DrivingAnalysis/DriverDetail'),
  //     loading: Loader
  //   })
  // },
  {
    private: true,
    path: '/fleet-health',
    exact: true,
    id: 13,
    // sideBarMenu: myFleetSideMenu,
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/FleetHealth'),
      loading: Loader,
    }),
    beta: false,
  },

  {
    private: true,
    path: '/daily-trip-analysis',
    exact: true,
    id: 14,
    // sideBarMenu: myFleetSideMenu,
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/DailyTripAnalysis'),
      loading: Loader,
    }),
    beta: false,
  },

  // {
  //   private: true,
  //   path: '/reports',
  //   exact: true,
  //   id: 15,
  //   // sideBarMenu: myFleetSideMenu,
  //   sideBar: false,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription,
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/Reports'),
  //     loading: Loader,
  //   }),
  // },

  {
    private: true,
    path: '/reportsv2',
    exact: true,
    id: 30,
    // sideBarMenu: myFleetSideMenu,
    sideBar: false,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/ReportsV2'),
      loading: Loader,
    }),
    beta: true,
  },
  // Customer-Admin
  {
    private: true,
    path: '/profiles/customer-admin',
    sideBar: true,
    id: 16,
    exact: true,
    sideBarMenu:
      userRole === 'ROOTADMIN' ? ProfileSideBarMenu : UserReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Profiles/CustomerAdmin'),
      loading: Loader,
    }),
  },
  // My Profiles
  {
    private: true,
    path: '/profiles/my-profile',
    sideBar: true,
    id: 17,
    exact: true,
    sideBarMenu:
      userRole === 'ROOTADMIN' ? ProfileSideBarMenu : UserReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Profiles/MyProfile'),
      loading: Loader,
    }),
    beta: false,
  },
  // Report
  // {
  //   private: true,
  //   path: '/reports',
  //   sideBar: false,
  //   exact: true,
  //   id: 18,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription,
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/Reports'),
  //     loading: Loader,
  //   }),
  //   beta: false,
  // },
  {
    private: true,
    path: '/reports/alert',
    id: 21,
    sideBar: false,
    exact: true,
    sideBarMenu: ReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Reports'),
      loading: Loader,
    }),
    beta: false,
  },
  // Vehicle Summary
  {
    private: true,
    path: '/reports/vehicle-summary',
    sideBar: true,
    exact: true,
    id: 19,
    sideBarMenu: ReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Reports/VehicleSummary'),
      loading: Loader,
    }),
    beta: false,
  },
  // Over Speed
  {
    private: true,
    path: '/reports/over-speed',
    sideBar: true,
    id: 20,
    exact: true,
    sideBarMenu: ReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Reports/OverSpeed'),
      loading: Loader,
    }),
    beta: false,
  },
  // CAN Signal
  // {
  //   private: true,
  //   path: '/reports/can-signal',
  //   sideBar: true,
  //   exact: true,
  //   sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/Reports/CANSignal'),
  //     loading: Loader
  //   })
  // },
  // Alert

  // Vehicle Instant Report
  {
    private: true,
    path: '/reports/vehicle-instant',
    id: 22,
    sideBar: true,
    exact: true,
    sideBarMenu: ReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Reports/VehicleInstantReport'),
      loading: Loader,
    }),
    beta: false,
  },
  // Fuel Consumption Report
  {
    private: true,
    path: '/reports/fuel-consumption',
    id: 23,
    sideBar: true,
    exact: true,
    sideBarMenu: ReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Reports/FuelConsumption'),
      loading: Loader,
    }),
    beta: false,
  },
  // Engine Over Run Report
  {
    private: true,
    path: '/reports/engine-overrun',
    id: 24,
    sideBar: true,
    exact: true,
    sideBarMenu: ReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Reports/EngineOverRun'),
      loading: Loader,
    }),
    beta: false,
  },
  // Neutral Driving Report
  {
    private: true,
    path: '/reports/neutral-driving',
    sideBar: true,
    id: 25,
    exact: true,
    sideBarMenu: ReportsSideBarMenu,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Reports/NeutralDriving'),
      loading: Loader,
    }),
    beta: false,
  },
  // Setting
  {
    private: true,
    path: '/settings',
    id: 26,
    sideBar: false,
    exact: true,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Settings'),
      loading: Loader,
    }),
    beta: false,
  },

  // DTC
  // {
  //   private: true,
  //   path: '/dtc',
  //   sideBar: false,
  //   exact: true,
  //   // sideBarMenu: ReportsSideBarMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/Reports/DTC'),
  //     loading: Loader
  //   })
  // }
  // Setting
  {
    private: true,
    path: '/support',
    id: 27,
    sideBar: false,
    exact: true,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Settings/Help'),
      loading: Loader,
    }),
    beta: false,
  },
  {
    private: true,
    path: '/alerts',
    id: 28,
    sideBar: true,
    exact: true,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/Alerts'),
      loading: Loader,
    }),
    beta: true,
  },
  {
    private: true,
    path: '/driving-analysisv2',
    id: 29,
    sideBar: true,
    exact: true,
    meta: {
      title: siteTitle,
      description: siteMetaDescription,
    },
    component: Loadable({
      loader: () => import('./components/DrivingAnalysisV2'),
      loading: Loader,
    }),
    beta: true,
  },
  // {
  //   private: true,
  //   exact: true,
  //   path: '/beta-access-control',
  //   sideBar: false,
  //   id: 34,
  //   // sideBarMenu: myFleetSideMenu,
  //   meta: {
  //     title: siteTitle,
  //     description: siteMetaDescription,
  //   },
  //   component: Loadable({
  //     loader: () => import('./components/BetaAccessControl'),
  //     loading: Loader,
  //   }),
  //   beta: false,
  // },
];

export default ROUTE;
