/* eslint-disable */
import React from 'react';
import moment from 'moment';
import downArrow from '../../assets/images/fmt_pop_up/down_arrow.png';
import upArrow from '../../assets/images/fmt_pop_up/up_arrow.png';

export default class LiveInfoWindowBeta extends React.Component {
  render() {
    const res = this.props.vehicleInfoData;
    let eta = '';
    if (this.props.apiT !== 'NA') {
      eta = moment()
        .add(this.props.apiT, 'seconds')
        .format('DD-MMM-YY hh:mm:ss');
    } else {
      eta = 'NA';
    }

    let style = '';

    switch (this.props.location) {
      case 'RUNNING':
        style = '#2c8243';
        break;
      case 'IDLE':
        style = '#ffa900';
        break;
      case 'STOPPED':
        style = '#d52929';
        break;
      case 'KEYON':
        style = 'gray';
        break;
      default:
        style = 'darkgray';
        break;
    }

    return (
      <div className="maps-popup">
        <div className="tempo">
          <div className="reponsive-popup">
            <div className="truck-info">
              <div className="" style={{ width: '100%' }}>
                <div className="flex items-center justify-between mb-3">
                  <span className="d-flex align-items-center">
                    <code
                      className="map-info-header ml-0"
                      style={{ backgroundColor: style }}
                    >
                      {this.props.regid}
                    </code>
                  </span>
                </div>

                <div
                  className="playbackImg mb-3 head info-bg-play"
                  id="liveButton"
                  vehicleId={this.props.vehicleId && this.props.vehicleId}
                  imei={this.props.imei && this.props.imei}
                >
                  Live
                </div>
                <div className="mb-3 d-flex justify-content-between">
                  <div className="flex-row">
                    <div className="head mb-1" id="vehicle-clicked">
                      Last Updated Date
                    </div>
                    <div>
                      {this.props.lastUpdatedDate
                        ? moment(this.props.lastUpdatedDate)
                            .utc(false)
                            .format('MMM Do YYYY')
                        : 'Not Available'}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div className="head mb-1" id="vehicle-clicked">
                      Last Updated Time
                    </div>
                    <div>
                      {this.props.lastUpdatedTime
                        ? this.props.lastUpdatedTime
                        : 'Not Available'}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="head mb-1" id="vehicle-clicked">
                    Location Details
                  </div>
                  <div className="location  info-bg-location" id="geoAddress" />
                  <div className="mb-2" id="w3wcontent" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
