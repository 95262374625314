/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export const DEF_SEARCH_PARAM = {
  vehicles: [],
  fromTsInIST: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  toTsInIST: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
};

export const liveTblHeadName = [
  { name: 'From', id: 'fromCity' },
  { name: 'To', id: 'toCity' },
  {
    name: 'Scheduled Start Date',
    id: 'scheduleStartDate',
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  {
    name: 'Scheduled End Date',
    id: 'scheduleEndDate',
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  { name: 'Truck', id: 'truck' },
  { name: 'Driver', id: 'driver' },
  { name: 'Avg Speed', id: 'avgSpeedInkmph', unitConversion: '(kmph)' },
  { name: 'Fuel Consumed', id: 'fuelConsumedInLtrs', unitConversion: '(ltrs)' },
  {
    name: 'Adblue Consumed',
    id: 'adblueConsumedInLtrs',
    unitConversion: '(ltrs)',
  },
  {
    name: 'Distance Travelled',
    id: 'distanceTravelledInkms',
    unitConversion: '(km)',
  },
  { name: 'Status', id: 'status', chip: true },
  { name: 'ETA', id: 'eta' },
  { name: 'Trip Share', id: 'share' },
];

export const scheduledTblHeadName = [
  { name: 'From', id: 'fromCity' },
  { name: 'To', id: 'toCity' },
  {
    name: 'Scheduled Start Date',
    id: 'scheduleStartDate',
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  {
    name: 'Scheduled End Date',
    id: 'scheduleEndDate',
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  { name: 'Truck', id: 'truck' },
  { name: 'Driver', id: 'driver' },
  { name: 'Load', id: 'load' },
  { name: 'Load Type', id: 'loadType' },
];

export const completedTblHeadName = [
  { name: 'From', id: 'fromCity' },
  { name: 'To', id: 'toCity' },
  {
    name: 'Scheduled Start Date',
    id: 'scheduleStartDate',
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  {
    name: 'Scheduled End Date',
    id: 'scheduleEndDate',
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  {
    name: 'Actual End Date',
    id: '',
    unitConversion: '(DD-MM-YYYY HH:MM:SS)',
  },
  { name: 'Truck', id: 'truck' },
  { name: 'Driver', id: 'driver' },
  { name: 'Avg Speed', id: 'avgSpeedInkmph', unitConversion: '(kmph)' },
  { name: 'Fuel Consumed', id: 'fuelConsumedInLtrs', unitConversion: '(ltrs)' },
  {
    name: 'Adblue Consumed',
    id: 'adblueConsumedInLtrs',
    unitConversion: '(ltrs)',
  },
  {
    name: 'Distance Travelled',
    id: 'distanceTravelledInkms',
    unitConversion: '(kms)',
  },
  { name: 'Score', id: 'score' },
  { name: 'Playback', id: 'playback' },
  { name: 'Status', id: 'status', chip: true },
];

export const applications = [
  { id: 1, name: 'Cement' },
  { id: 2, name: 'ECommerce' },
  { id: 3, name: 'Mining' },
  { id: 4, name: 'Agricultural produce' },
  { id: 5, name: 'Auto Carriers' },
  { id: 6, name: 'Cement Transportation' },
  { id: 7, name: 'Cold Chain - Dairy, meat and pharma' },
  { id: 8, name: 'Construction' },
  { id: 9, name: 'Fish' },
  { id: 10, name: 'Liquid/Gas transport' },
  { id: 11, name: 'Market load' },
  { id: 12, name: 'Milk' },
  { id: 13, name: 'Ores & Minerals Mining' },
  { id: 14, name: 'Parcel/FMCG/FMCD' },
  { id: 15, name: 'Port Movement' },
  { id: 16, name: 'Poultry' },
  { id: 17, name: 'Steel/Industrial goods/Auto Ancillaries' },
  { id: 18, name: 'Textile Transportation' },
  { id: 19, name: 'Waste Management' },
];

export const roadType = [
  { id: 1, name: 'Highway' },
  { id: 2, name: 'Intracity' },
  { id: 3, name: 'Others' },
  { id: 4, name: 'Intercity' },
];

export const loadType = [
  { id: 1, name: 'Full Load' },
  { id: 2, name: 'Partial Load' },
  { id: 3, name: 'Empty' },
];
