/* eslint-disable */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import closeIcon from '../../assets/images/closeIcon.svg';

const AlertNotificationPopup = ({
  alert,
  closePopup,
  showAllAlerts,
  toggleShowAll,
  allAlerts,
  history,
}) => {
  const { type, details } = alert;

  const formattedDate = moment(details.Date)
    .utc(false)
    .format('MMM Do YYYY, h:mm a');
  const message = `${type} alert occurred for ${details.RegistrationNumber} on ${formattedDate}`;

  const goToAlerts = details => {
    history.push({
      pathname: '/alerts',
      state: {
        page: 'alerts',
        data: details,
        type,
      },
    });
    closePopup();
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      closePopup();
    }, 60000); // Close popup after 1 minute of inactivity

    const handleUserActivity = () => {
      clearTimeout(timer); // Reset the timer on user activity
      timer = setTimeout(() => {
        closePopup();
      }, 60000); // Restart the timer
    };

    // Event listeners for mouse movement and clicks
    document.addEventListener('mousemove', handleUserActivity);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousemove', handleUserActivity);
    };
  }, [closePopup]);

  return (
    <div className="AlertPopup">
      <div className="popup-content">
        {!showAllAlerts && (
          <>
            <div className="alertMessage">{message}</div>
            <div className="showAllAlert">
              <button onClick={toggleShowAll}>Show all alerts</button>
            </div>
          </>
        )}
        {showAllAlerts && (
          <div className="showAllAlertArea">
            {allAlerts.map((alert, index) => (
              <div key={index}>
                {alert.type} alert occurred for{' '}
                {alert.details.RegistrationNumber} on{' '}
                {moment(alert.details.Date)
                  .utc(false)
                  .format('MMM Do YYYY, h:mm a')}
                <button
                  className="goToAlert"
                  onClick={() => goToAlerts(alert.details)}
                >
                  Go to alert
                </button>
              </div>
            ))}
          </div>
        )}
        <button className="closeAlertIcon" onClick={closePopup}>
          <img src={closeIcon} alt="" />
        </button>
      </div>
    </div>
  );
};

export default withRouter(AlertNotificationPopup);
