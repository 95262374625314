/* eslint-disable */

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import LiveVehiclePaneBeta from './LiveVehiclePaneBeta';

const LiveLeftCellBeta = props => {
  const {
    isExpand,
    isLiveTrackingEnabled,
    isGridMap,
    filterDataFMT,
    isInPlayBackStill,
  } = props;

  const clientHgt = document.documentElement.clientHeight;
  const divHeight = (clientHgt * 80) / 100;
  const eachHeight = divHeight / 8 - 8;

  return (
    <Col md={isExpand ? 4 : 2} className="mt-4 expnd live-map-sidebar bg-color">
      <div className="p-2"></div>
      <LiveVehiclePaneBeta
        isExpand={isExpand}
        isGridMap={isGridMap}
        clientHgt={clientHgt}
        eachHeight={eachHeight}
        drawRoute1={props.drawRoute1}
        filterDataFMT={filterDataFMT}
        isInPlayBackStill={isInPlayBackStill}
        regIdClickParent={props.regIdClickParent}
        isLiveTrackingEnabled={isLiveTrackingEnabled}
        divHeight={divHeight}
      />
    </Col>
  );
};

export default LiveLeftCellBeta;
