import { combineReducers } from 'redux';
import alertReducer from './alert';
import drivingAnalysisReducer from './drivingAnalysis';
import {
  DEFAULT_STATE,
  PENDING,
  FULFILLED,
  REJECTED,
  DEFAULT_STATE_FF_EF,
} from '../Helpers/Constants';
import reportsReducer from './reports';
import trackTraceReducer from './trackTrace';
import dashboardReducer from './dashboard';
import tripManagmentReducer from './tripManagement';

// reducer to manage UI state

const initUIState = {
  selectedPeriod: 'last7Days',
  selectedVehicleIds: [],
  themeSelected: localStorage.getItem('theme')
    ? localStorage.getItem('theme')
    : 'light',
  uiSelected: localStorage.getItem('uiType')
    ? localStorage.getItem('uiType')
    : 'alpha',
};

function uiReducer(state = initUIState, action) {
  switch (action.type) {
    case 'UPDATE_SELECTED_PERIOD':
      return { ...state, selectedPeriod: action.value };
    case 'UPDATE_VEHICLE_ID':
      return { ...state, selectedVehicleIds: [...action.value] };
    case 'TOGGLE_THEME':
      return {
        ...state,
        themeSelected: action.payload,
      };
    case 'TOGGLE_BETA':
      return {
        ...state,
        uiSelected: action.payload,
      };
    default:
      return state;
  }
}

/**
 * Common funtion to create the reducer
 * @param {*} reducerName name of operation
 * @param {*} defaultStateParam default states
 */
const createReducer = (reducerName, defaultStateParam) => (state, action) => {
  // eslint-disable-next-line no-param-reassign
  state = state || defaultStateParam || DEFAULT_STATE;
  switch (action.type) {
    case `${reducerName}_${PENDING}`:
    case `${reducerName}_${FULFILLED}`:
    case `${reducerName}_${REJECTED}`:
      return { ...action.payload };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  uiState: uiReducer,
  alertState: alertReducer,
  dashboardState: dashboardReducer,
  drivingAnalysisState: drivingAnalysisReducer,
  reportsState: reportsReducer,
  trackTraceState: trackTraceReducer,
  tripManagementState: tripManagmentReducer,
  login: createReducer('LOGIN', DEFAULT_STATE_FF_EF),
  driverProfile: createReducer('DRIVER_PROFILE', DEFAULT_STATE_FF_EF),
  fleetManagerProfile: createReducer(
    'FLEET_MANAGER_PROFILE',
    DEFAULT_STATE_FF_EF
  ),
  csSupportProfile: createReducer(
    'CS_SUPPORT_PROFILE',
    DEFAULT_STATE_FF_EF
  ),
  deleteActiveUser: createReducer('DELETE_ACTIVE_USER', DEFAULT_STATE_FF_EF),
  restoreInactiveUser: createReducer(
    'RESTORE_INACTIVE_USER',
    DEFAULT_STATE_FF_EF
  ),
  rootAdminProfile: createReducer('ROOT_ADMIN_PROFILE', DEFAULT_STATE_FF_EF),
  downloadProfiles: createReducer('PROFILE_DOWNLOAD', DEFAULT_STATE_FF_EF),
  editDriverProfile: createReducer('EDIT_DRIVER_PROFILE', DEFAULT_STATE_FF_EF),
  getNotificationList: createReducer(
    'GET_MYFLEET_NOTIFICATION_LIST',
    DEFAULT_STATE_FF_EF
  ),
  geofenceEditDetails: createReducer(
    'GEOFENCE_EDIT_DETAILS',
    DEFAULT_STATE_FF_EF
  ),
  getMyFleetPlaBackVehiclesData: createReducer(
    'GET_MY_FLEET_PLAYBACK_VEHICLES',
    DEFAULT_STATE_FF_EF
  ),
  deleteGeoFenceNewPortal: createReducer(
    'DELETE_GEOFENCE_NEW',
    DEFAULT_STATE_FF_EF
  ),
  getGeofenceDropdownList: createReducer(
    'GET_GEOFENCE_DROPDOWN',
    DEFAULT_STATE_FF_EF
  ),
  getVehicleVariant: createReducer('GET_VEHICLE_VARIANT', DEFAULT_STATE_FF_EF),
  generateXlsReportForFleetHealth: createReducer(
    'XLS_FLEETHEALTH',
    DEFAULT_STATE_FF_EF
  ),
  getgrprgn: createReducer('GET_FMT_GR', DEFAULT_STATE_FF_EF),
  submitGeofenceNew: createReducer('SUBMIT_GEO_NEW', DEFAULT_STATE_FF_EF),
  generatePdfReportForFleetHealth: createReducer(
    'PDF_FLEETHEALTH',
    DEFAULT_STATE_FF_EF
  ),
  viewGoogleMapAPi: createReducer('GOOGLE_MAP_LOC', DEFAULT_STATE_FF_EF),
  viewGoogleMapAPiOverspeed: createReducer(
    'GOOGLE_MAP_LOC_OVER',
    DEFAULT_STATE_FF_EF
  ),
  viewGoogleMapAPiNeutral: createReducer(
    'GOOGLE_MAP_LOC_NUT',
    DEFAULT_STATE_FF_EF
  ),
  vehicleSummaryReportes: createReducer(
    'VEHICLE_SUMMARY_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  viewGoogleMapAPiRun: createReducer('GOOGLE_MAP_LOC_RUN', DEFAULT_STATE_FF_EF),
  downloadPdfVehicle: createReducer(
    'PDF_DOWNLOAD_VEHICLE',
    DEFAULT_STATE_FF_EF
  ),

  downloadProfilesDriverNew: createReducer(
    'PDF_DOWNLOAD_DRIVER',
    DEFAULT_STATE_FF_EF
  ),
  downloadProfilesDealerNew: createReducer(
    'PDF_DOWNLOAD_DEALER',
    DEFAULT_STATE_FF_EF
  ),
  downloadPdfCustomer: createReducer('PDF_CUSTOMER_NEW', DEFAULT_STATE_FF_EF),
  getCountryList: createReducer('GET_COUNTRY_LIST', DEFAULT_STATE_FF_EF),
  getMappedVehicles: createReducer('GET_VEHICLE_CATEGORY', DEFAULT_STATE_FF_EF),
  getCustomerList: createReducer('GET_CUSTOMER_LIST', DEFAULT_STATE_FF_EF),
  getGroupList: createReducer('GET_GROUP_LIST', DEFAULT_STATE_FF_EF),
  vehicleProfile: createReducer('VEHICLE_PROFILE', DEFAULT_STATE_FF_EF),
  getUserDetail: createReducer('GET_USER_DETAIL', DEFAULT_STATE_FF_EF),
  getGpsMyFleet: createReducer('GET_GPS_MYFLEET', DEFAULT_STATE_FF_EF),
  getCompanyForCustomerAdmin: createReducer(
    'GET_COMPANY_LIST_FOR_CUSTOMER',
    DEFAULT_STATE_FF_EF
  ),
  getCompanyForVehicleProfile: createReducer(
    'GET_COMPANY_LIST_FOR_VEHICLE',
    DEFAULT_STATE_FF_EF
  ),
  getCompanyForDriverProfile: createReducer(
    'GET_COMPANY_LIST',
    DEFAULT_STATE_FF_EF
  ),
  getVehicleInfofmt: createReducer('VEHICLE_INFO_FMT', DEFAULT_STATE_FF_EF),
  deleteDriverProfile: createReducer(
    'DELETE_DRIVER_PROFILE',
    DEFAULT_STATE_FF_EF
  ),
  getCategoryForVehicleProfile: createReducer(
    'GET_VEHICLE_CATEGORY_LIST',
    DEFAULT_STATE_FF_EF
  ),
  getVehicleTypeList: createReducer(
    'GET_VEHICLE_TYPE_LIST',
    DEFAULT_STATE_FF_EF
  ),
  downloadProfilesVehicle: createReducer(
    'PROFILE_DOWNLOAD_VEHICLE',
    DEFAULT_STATE_FF_EF
  ),
  downloadProfilesDriver: createReducer(
    'PROFILE_DOWNLOAD_DRIVER',
    DEFAULT_STATE_FF_EF
  ),
  downloadProfilesDealer: createReducer(
    'PROFILE_DOWNLOAD_DEALER',
    DEFAULT_STATE_FF_EF
  ),

  getGeofenceSearchList: createReducer(
    'GEOFENCE_SEARCH_LIST',
    DEFAULT_STATE_FF_EF
  ),
  downloadProfilesCustomer: createReducer(
    'PROFILE_DOWNLOAD_CUSTOMER',
    DEFAULT_STATE_FF_EF
  ),
  getVehicleDashboardReport: createReducer(
    'GET_VEHICLES_DASHBOARD_REPORT_LIST',
    DEFAULT_STATE_FF_EF
  ),
  getFilterFMTData: createReducer('FILTER_FMT', DEFAULT_STATE_FF_EF),
  getFleetHealthData: createReducer(
    'GET_FLEET_HEALTH_DATA',
    DEFAULT_STATE_FF_EF
  ),

  getVehiclesData: createReducer('GET_VEHICLES_LIST', DEFAULT_STATE_FF_EF),
  getVehicleDetail: createReducer('GET_VEHICLE_DETAIL', DEFAULT_STATE_FF_EF),
  getRegionList: createReducer('GET_REGION_LIST', DEFAULT_STATE_FF_EF),
  getSelectedUsers: createReducer(
    'GET_SELECTED_USER_LIST',
    DEFAULT_STATE_FF_EF
  ),
  getAdminGroup: createReducer('GET_ADMIN_USER_LIST', DEFAULT_STATE_FF_EF),
  deleteVehicleProfile: createReducer(
    'DELETE_VEHICLE_PROFILE',
    DEFAULT_STATE_FF_EF
  ),

  editVehicleProfile: createReducer(
    'EDIT_VEHICLE_PROFILE',
    DEFAULT_STATE_FF_EF
  ),
  customerAdmin: createReducer('CUSTOMER_ADMIN', DEFAULT_STATE_FF_EF),
  editCustomerAdmins: createReducer('EDIT_CUSTOMER_ADMIN', DEFAULT_STATE_FF_EF),
  editCustomerAdmins1: createReducer(
    'EDIT_CUSTOMER_ADMIN1',
    DEFAULT_STATE_FF_EF
  ),
  createCustomerManager: createReducer(
    'CREATE_CUSTOMER_MANAGER',
    DEFAULT_STATE_FF_EF
  ),
  createFleetManager: createReducer(
    'CREATE_FLEET_MANAGER',
    DEFAULT_STATE_FF_EF
  ),
  deleteCustomerAdmins: createReducer(
    'DELETE_CUSTOMER_ADMIN',
    DEFAULT_STATE_FF_EF
  ),
  forgotPassword: createReducer('FORGOT_PASSWORD', DEFAULT_STATE_FF_EF),
  dealerProfile: createReducer('GET_DEALER_PROFILE', DEFAULT_STATE_FF_EF),
  saveDealerProfile: createReducer(
    'CREATE_DEALER_PROFILE',
    DEFAULT_STATE_FF_EF
  ),
  deleteDealerProfile: createReducer(
    'DELETE_DEALER_PROFILE',
    DEFAULT_STATE_FF_EF
  ),
  // dashboardOverview: createReducer('DASHBOARD_OVERVIEW', DEFAULT_STATE_FF_EF),
  getMyProfile: createReducer('GET_MY_PROFILE', DEFAULT_STATE_FF_EF),
  saveMyProfile: createReducer('UPDATE_MY_ADMIN_PROFILE', DEFAULT_STATE_FF_EF),

  // MyFleet
  getGroups: createReducer('GET_GROUP', DEFAULT_STATE_FF_EF),
  getDriverAnalysisFromTrip: createReducer(
    'GET_DRIVER_FROM_TRIP',
    DEFAULT_STATE_FF_EF
  ),
  getNotification: createReducer('GET_NOTIFICATIONS', DEFAULT_STATE_FF_EF),
  getMyFleetVehicles: createReducer(
    'GET_MY_FLEET_VEHICLES',
    DEFAULT_STATE_FF_EF
  ),
  getGeoFenceData: createReducer('GET_GEOFENCE_DATA', DEFAULT_STATE_FF_EF),
  getGeoFenceList: createReducer('GET_GEOFENCE_LIST', DEFAULT_STATE_FF_EF),
  saveGeoFence: createReducer('SAVE_GEOFENCE', DEFAULT_STATE_FF_EF),
  updateGeFenceData: createReducer('POST_GEOFENCE_DATA', DEFAULT_STATE_FF_EF),
  myFleetData: createReducer('GET_MYFLEET_DATA', DEFAULT_STATE_FF_EF),
  deleteGeoFence: createReducer('DELETE_GEOFENCE', DEFAULT_STATE_FF_EF),
  getDTSNotificationData: createReducer(
    'GET_DTC_NOTIFICATION',
    DEFAULT_STATE_FF_EF
  ),
  // Daily Trip Analysis
  getDailyTripListWithDistance: createReducer(
    'GET_DAILY_TRIP_ANALYSIS_LIST',
    DEFAULT_STATE_FF_EF
  ),
  getMyFleetPlayback: createReducer(
    'GET_DAILY_TRIP_MY_FLEET_VEHICLE',
    DEFAULT_STATE_FF_EF
  ),
  // Reports
  getVehicleSummaryReportsData: createReducer(
    'VEHICLE_SUMMARY_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  getActiveVehicleData: createReducer(
    'GET_ACTIVE_VEHICLES',
    DEFAULT_STATE_FF_EF
  ),

  getActiveVehicles: createReducer('GET_ACTIVE_VEHICLES', DEFAULT_STATE_FF_EF),

  getVehicles: createReducer('GET_VEHICLES', DEFAULT_STATE_FF_EF),

  getCompaniesFmt: createReducer('GET_COMP_FMT', DEFAULT_STATE_FF_EF),

  generateVehicleSummaryReports: createReducer(
    'GENERATE_VEHICLES_SUMMARY_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateVehicleSummaryPDFReports: createReducer(
    'GENERATE_VEHICLES_SUMMARY_PDF_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  overSpeedReports: createReducer('OVER_SPEED_REPORTS', DEFAULT_STATE_FF_EF),
  generateOverSpeedReportsXls: createReducer(
    'OVER_SPEED_REPORTS_XLS',
    DEFAULT_STATE_FF_EF
  ),
  generateOverSpeedPDFReports: createReducer(
    'GENERATE_OVER_SPEED_PDF_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  fuelConsumptionReports: createReducer(
    'FUEL_CONSUMPTION_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateFuelConsumptionReportsXls: createReducer(
    'FUEL_CONSUMPTION_REPORTS_XLS',
    DEFAULT_STATE_FF_EF
  ),
  generateFuelConsumptionReportsPdf: createReducer(
    'FUEL_CONSUMPTION_REPORTS_PDF',
    DEFAULT_STATE_FF_EF
  ),
  getGroupsByReports: createReducer(
    'GET_GROUPS_OF_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  vehicleInstantaneousReports: createReducer(
    'VEHICLE_INSTANTANEOUS_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateVehicleInstantXLSReports: createReducer(
    'GENERATE_VEHICLE_INSTANT_XLS_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateVehicleInstantPDFReports: createReducer(
    'GENERATE_VEHICLE_INSTANT_PDF_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  getAlertTypes: createReducer('GET_ALERT_TYPES', DEFAULT_STATE_FF_EF),
  viewAlertReports: createReducer('VIEW_ALERT_REPORTS', DEFAULT_STATE_FF_EF),
  generateAlertReports: createReducer(
    'GENERATE_ALERT_XLS_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateAlertPDFReports: createReducer(
    'GENERATE_ALERT_PDF_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateCanSignalXlsReports: createReducer(
    'GENERATE_CAN_SINGAL_XLS_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  viewEngineOverRunReports: createReducer(
    'VIEW_ENGINE_OVER_RUN_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateEngineOverRunXlsReports: createReducer(
    'GENERATE_ENGINE_OVER_RUN_XLS_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateEngineOverRunPdfReports: createReducer(
    'GENERATE_ENGINE_OVER_RUN_PDF_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  viewNeutralDrivingReports: createReducer(
    'VIEW_NEUTRAL_DRIVING_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateNeutralDrivingXlsReports: createReducer(
    'GENERATE_NEUTRAL_DRIVING_XLS_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateNeutralDrivingPdfReports: createReducer(
    'GENERATE_NEUTRAL_DRIVING_PDF_REPORTS',
    DEFAULT_STATE_FF_EF
  ),

  showAllTrucks: createReducer('SHOW_ALL_TRUCKS', DEFAULT_STATE_FF_EF),

  showAllTrucksDashboard: createReducer(
    'SHOW_ALL_TRUCKS_DASHBOARD',
    DEFAULT_STATE_FF_EF
  ),

  getSearchVehicleIds: createReducer(
    'GET_VEHICLE_SEARCH_IDS',
    DEFAULT_STATE_FF_EF
  ),

  getLiveNotification: createReducer('LIVE_NOTIFICATION', DEFAULT_STATE_FF_EF),
  // DTC Reports
  viewDTCReports: createReducer('VIEW_DTC_REPORTS', DEFAULT_STATE_FF_EF),
  generateDTCXls: createReducer(
    'GENERATE_DTC_XLS_REPORTS',
    DEFAULT_STATE_FF_EF
  ),
  generateDTCPdf: createReducer(
    'GENERATE_DTC_PDF_REPORTS',
    DEFAULT_STATE_FF_EF
  ),

  // settings

  addVehicleTypeData: createReducer('ADD_VEHICLE_TYPE', DEFAULT_STATE_FF_EF),
  getVehicleTypeData: createReducer('GET_VEHICLE_TYPE', DEFAULT_STATE_FF_EF),
  addVehicleCategoryData: createReducer(
    'ADD_VEHICLE_CATEGORY',
    DEFAULT_STATE_FF_EF
  ),
  getVehicleCategoryData: createReducer(
    'GET_VEHICLE_CATEGORY',
    DEFAULT_STATE_FF_EF
  ),
  getGeofenceTypeData: createReducer('GET_GEOFENCE_TYPE', DEFAULT_STATE_FF_EF),
  addGeofenceTypeData: createReducer('ADD_GEOFENCE_TYPE', DEFAULT_STATE_FF_EF),
  getGroupsData: createReducer('GET_GROUP', DEFAULT_STATE_FF_EF),
  addGroupsData: createReducer('ADD_GROUP', DEFAULT_STATE_FF_EF),
  getRegionData: createReducer('GET_REGIONS', DEFAULT_STATE_FF_EF),
  addRegionData: createReducer('ADD_REGIONS', DEFAULT_STATE_FF_EF),
  getCompaniesData: createReducer('GET_COMPANIES', DEFAULT_STATE_FF_EF),
  addCompanyData: createReducer('ADD_COMPANY', DEFAULT_STATE_FF_EF),
  getAlertPreferenceData: createReducer(
    'GET_USER_ALERT_PREFERENCE',
    DEFAULT_STATE_FF_EF
  ),
  addAlertPreferenceData: createReducer(
    'ADD_USER_ALERT_PREFERENCE',
    DEFAULT_STATE_FF_EF
  ),
  driverAnalysisList: createReducer(
    'GET_DRIVER_FROM_TRIP',
    DEFAULT_STATE_FF_EF
  ),
  changePasswordData: createReducer('CHANGE_PASSWORD', DEFAULT_STATE_FF_EF),
  getScalingFactorData: createReducer(
    'GET_SCALING_FACTOR',
    DEFAULT_STATE_FF_EF
  ),
  addScalingFactorData: createReducer(
    'ADD_SCALING_FACTOR',
    DEFAULT_STATE_FF_EF
  ),
  getVehicleUtilization: createReducer(
    'GET_VEHICLES_UTILIZATION',
    DEFAULT_STATE_FF_EF
  ),

  getAlertSettingsData: createReducer(
    'GET_ALERT_SETTINGS',
    DEFAULT_STATE_FF_EF
  ),
  addAlertSettingsData: createReducer(
    'ADD_ALERT_SETTINGS',
    DEFAULT_STATE_FF_EF
  ),

  setMaintainanceStatus: createReducer(
    'SET_MAINTAINANCE_STATUS',
    DEFAULT_STATE_FF_EF
  ),

  getMaintainanceStatus: createReducer(
    'GET_MAINTAINANCE_STATUS',
    DEFAULT_STATE_FF_EF
  ),

  driverAnalysisForPerformanceGraph: createReducer(
    'GET_DRIVER_PERFORMANCE_GRAPH',
    DEFAULT_STATE_FF_EF
  ),
  getDriverInsights: createReducer('GET_DRIVER_INSIGHTS', DEFAULT_STATE_FF_EF),
  getSpeedingCountForDriver: createReducer(
    'GET_DRIVER_SPEEDING_COUNT',
    DEFAULT_STATE_FF_EF
  ),
  getVehicleUtilizationGraph: createReducer(
    'GET_VEHICLES_UTILIZATION_GRAPH',
    DEFAULT_STATE_FF_EF
  ),
  getDailyTripXlsReport: createReducer(
    'GENERATE_DTA_SUMMARY_REPORT',
    DEFAULT_STATE_FF_EF
  ),
  generatePdfReportForTripManagement: createReducer(
    'GENERATE_DTA_PDF_REPORT',
    DEFAULT_STATE_FF_EF
  ),
  generateXlsReportForTripManagement: createReducer(
    'GENERATE_DTA_XLS_REPORT',
    DEFAULT_STATE_FF_EF
  ),
  getDailyTripPdfReport: createReducer(
    'GENERATE_DTA_PDF_REPORT',
    DEFAULT_STATE_FF_EF
  ),
  getMileageDataWeekly: createReducer(
    'GET_MILEAGE_DATA_WEEKLY',
    DEFAULT_STATE_FF_EF
  ),
  getMileageDataMonthly: createReducer(
    'GET_MILEAGE_DATA_MONTHLY',
    DEFAULT_STATE_FF_EF
  ),
  getMileageDataDaily: createReducer(
    'GET_MILEAGE_DATA_DAILY',
    DEFAULT_STATE_FF_EF
  ),
  getMileageDataYearly: createReducer(
    'GET_MILEAGE_DATA_YEARLY',
    DEFAULT_STATE_FF_EF
  ),
  getMileageDataVehicle: createReducer(
    'GET_MILEAGE_DATA_VEHICLE',
    DEFAULT_STATE_FF_EF
  ),
  getUtilizationData: createReducer(
    'GET_UTILIZATION_DATA_WEEKLY',
    DEFAULT_STATE_FF_EF
  ),
  getAdblueDataWeekly: createReducer(
    'GET_ADBLUE_DATA_WEEKLY',
    DEFAULT_STATE_FF_EF
  ),
  getAdblueDataMonthly: createReducer(
    'GET_ADBLUE_DATA_MONTHLY',
    DEFAULT_STATE_FF_EF
  ),
  getAdblueDataDaily: createReducer(
    'GET_ADBLUE_DATA_DAILY',
    DEFAULT_STATE_FF_EF
  ),
  getAdblueDataYearly: createReducer(
    'GET_ADBLUE_DATA_YEARLY',
    DEFAULT_STATE_FF_EF
  ),
  getAdblueDataVehicle: createReducer(
    'GET_ADBLUE_DATA_VEHICLE',
    DEFAULT_STATE_FF_EF
  ),

  getUtilizationDataWeekly: createReducer(
    'GET_UTILIZATION_DATA_WEEKLY',
    DEFAULT_STATE_FF_EF
  ),
  getUtilizationDataMonthly: createReducer(
    'GET_UTILIZATION_DATA_MONTHLY',
    DEFAULT_STATE_FF_EF
  ),
  getUtilizationDataDaily: createReducer(
    'GET_UTILIZATION_DATA_DAILY',
    DEFAULT_STATE_FF_EF
  ),
  getUtilizationDataYearly: createReducer(
    'GET_UTILIZATION_DATA_YEARLY',
    DEFAULT_STATE_FF_EF
  ),
  getUtilizationDataVehicle: createReducer(
    'GET_UTILIZATION_DATA_VEHICLE',
    DEFAULT_STATE_FF_EF
  ),
  getUnsafeEventsData: createReducer(
    'GET_UNSAFE_EVENTS_DATA_WEEKLY',
    DEFAULT_STATE_FF_EF
  ),
  getVehicleDropdownData: createReducer(
    'GET_VEHICLE_DROPDOWN_DATA',
    DEFAULT_STATE_FF_EF
  ),
  getRPMDataWeekly: createReducer('GET_RPM_DATA_WEEKLY', DEFAULT_STATE_FF_EF),
  getRPMData: createReducer('GET_RPM_DATA', DEFAULT_STATE_FF_EF),
  getRPMDataMonthly: createReducer('GET_RPM_DATA_MONTHLY', DEFAULT_STATE_FF_EF),
  getRPMDataYearly: createReducer('GET_RPM_DATA_YEARLY', DEFAULT_STATE_FF_EF),
  getRPMDataVehicle: createReducer('GET_RPM_DATA_VEHICLE', DEFAULT_STATE_FF_EF),
  getSpeedBandDataWeekly: createReducer(
    'GET_SPEED_DATA_WEEKLY',
    DEFAULT_STATE_FF_EF
  ),
  getSpeedBandDataMonthly: createReducer(
    'GET_SPEED_DATA_MONTHLY',
    DEFAULT_STATE_FF_EF
  ),
  getSpeedBandDataYearly: createReducer(
    'GET_SPEED_DATA_YEARLY',
    DEFAULT_STATE_FF_EF
  ),
  getSpeedBandDataVehicle: createReducer(
    'GET_SPEED_DATA_VEHICLE',
    DEFAULT_STATE_FF_EF
  ),
  getSpeedBandData: createReducer('GET_SPEED_DATA', DEFAULT_STATE_FF_EF),
  getTripStatus: createReducer('GET_TRIP_STATUS', DEFAULT_STATE_FF_EF),
  getDTCTickerData: createReducer('GET_DTC_TICKER_DATA', DEFAULT_STATE_FF_EF),
  getPerformance: createReducer('GET_PERFORMANCE', DEFAULT_STATE_FF_EF),
  getFleetStatus: createReducer('GET_FLEETSTATUS', DEFAULT_STATE_FF_EF),
  getDriverScore: createReducer('GET_DRIVERSCORE', DEFAULT_STATE_FF_EF),
  getLiveDTC: createReducer('GET_LIVE_DTC', DEFAULT_STATE_FF_EF),
  getInsights: createReducer('GET_INSIGHTS', DEFAULT_STATE_FF_EF),
  getInsightsWeekly: createReducer('GET_INSIGHTS_WEEKLY', DEFAULT_STATE_FF_EF),
  getInsightsMonthly: createReducer(
    'GET_INSIGHTS_MONTHLY',
    DEFAULT_STATE_FF_EF
  ),
  getInsightsYearly: createReducer('GET_INSIGHTS_YEARLY', DEFAULT_STATE_FF_EF),
  getInsightsVehicle: createReducer(
    'GET_INSIGHTS_VEHICLE',
    DEFAULT_STATE_FF_EF
  ),

  getActiveVehiclesTrip: createReducer('GET_TRUCKS', DEFAULT_STATE_FF_EF),
  getActiveDriversForNewTrip: createReducer(
    'GET_DRIVERLIST',
    DEFAULT_STATE_FF_EF
  ),
  createNewTrip: createReducer('CREATE_NEW_TRIP', DEFAULT_STATE_FF_EF),
  checkTruckDriverAvailability: createReducer(
    'CHECK_DRIVER_AVAILABILITY',
    DEFAULT_STATE_FF_EF
  ),
  getTripDetail: createReducer('GET_TRIP_DETAIL', DEFAULT_STATE_FF_EF),
  endTripManually: createReducer('END_TRIP', DEFAULT_STATE_FF_EF),
  getTripRecordCount: createReducer('GET_TRIP', DEFAULT_STATE_FF_EF),
  getLiveLocation: createReducer('GET_LIVE_LOCATION', DEFAULT_STATE_FF_EF),
  getTripUpdate: createReducer('GET_TRIP_UPDATE', DEFAULT_STATE_FF_EF),
  plotLiveTracking: createReducer('PLOT_LIVE_TRACK', DEFAULT_STATE_FF_EF),
  routePlayBack: createReducer('PLOT_ROUTE_PLAY_BACK', DEFAULT_STATE_FF_EF),
  gettrucksummary: createReducer('GET_TRUCK_SUMMARY', DEFAULT_STATE_FF_EF),
  getFuelCount: createReducer('GET_FUEL_COUNT', DEFAULT_STATE_FF_EF),
  getTruckScore: createReducer('GET_TRUCK_SCORE', DEFAULT_STATE_FF_EF),
  getTruckInsights: createReducer('GET_TRUCK_INSIGHTS', DEFAULT_STATE_FF_EF),
  getRpmBrand: createReducer('GET_RPM_BRAND', DEFAULT_STATE_FF_EF),
  getSpeedBrand: createReducer('GET_SPEED_BRAND', DEFAULT_STATE_FF_EF),
  getTruckDtc: createReducer('GET_TRUCK_DTC', DEFAULT_STATE_FF_EF),

  generatePdfReport: createReducer('GENERATE_PDF', DEFAULT_STATE_FF_EF),
  generateXLSReport: createReducer('GENERATE_XLS', DEFAULT_STATE_FF_EF),
  getServiceRemainderCount: createReducer(
    'GET_SERVICE_REMAINDER_COUNT',
    DEFAULT_STATE_FF_EF
  ),
  selectServiceType: createReducer('GET_SERVICE_TYPE', DEFAULT_STATE_FF_EF),
  selectServiceForDueSoon: createReducer(
    'GET_SERVICE_DueSoon',
    DEFAULT_STATE_FF_EF
  ),
  serviceDone: createReducer('GET_SERVICE_DONE', DEFAULT_STATE_FF_EF),
  alertsPlayBack: createReducer('PLOT_ALERTS_FMT', DEFAULT_STATE_FF_EF),
  getAlphaBetaUsers: createReducer('GET_AB_USERS', DEFAULT_STATE_FF_EF),
  updateAlphaBetaUsers: createReducer('UPDATE_AB_USERS', DEFAULT_STATE_FF_EF),
  singleAPIData: createReducer('GET_SINGLE_API_STATUS', DEFAULT_STATE_FF_EF),
});

export default rootReducer;
