/* eslint-disable */
import React from 'react';
import moment from 'moment';
import downArrow from '../../../assets/images/fmt_pop_up/down_arrow.png';
import upArrow from '../../../assets/images/fmt_pop_up/up_arrow.png';

export default class LiveInfoWindow extends React.Component {
  render() {
    const res = this.props.vehicleInfoData;
    let eta = '';
    if (this.props.apiT !== 'NA') {
      eta = moment()
        .add(this.props.apiT, 'seconds')
        .format('DD-MMM-YY hh:mm:ss');
    } else {
      eta = 'NA';
    }

    return (
      <div className="maps-popup">
        <div className="tempo">
          <div className="reponsive-popup">
            <div className="truck-info">
              <div className="" style={{ width: '100%' }}>
                <div className="flex items-center justify-between mb-3">
                  <div>
                    <div className="mb-1 head">Registration ID</div>
                    <div className="head">{this.props.regid}</div>
                  </div>

                  <div
                    role="presentation"
                    className="d-flex"
                    style={{ alignItems: 'center' }}
                  >
                    {this.props.tripDetails ? (
                      <div className="fmt-trip-label">IN TRIP</div>
                    ) : (
                      <div className="fmt-trip-label-not">NOT IN TRIP</div>
                    )}
                  </div>
                </div>

                <div
                  className="playbackImg mb-3 head info-bg-play"
                  id="liveButton"
                  vehicleId={this.props.vehicleId && this.props.vehicleId}
                  imei={this.props.imei && this.props.imei}
                >
                  Live
                </div>
                <div className="mb-3">
                  <div className="head mb-1" id="vehicle-clicked">
                    Location Details
                  </div>

                  <div className="location  info-bg-location" id="geoAddress" />
                  <div className="mb-2" id="w3wcontent" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
