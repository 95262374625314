import React, { useContext } from 'react';
import Modal from 'react-responsive-modal';
import LogoWhite from '../../assets/images/tk_logo_white.png';
import LogoBlue from '../../assets/images/tk_logo_blue.png';
import privacyData from '../../Helpers/Privacy';
import themeSelected from '../Common/Context';

function PolicyPopup(props) {
  const { open, onClose } = props;
  const themeType = useContext(themeSelected);

  return (
    <>
      <Modal open={open} onClose={() => onClose()}>
        <div
          className={
            themeType.theme === 'dark'
              ? 'privacyContainer'
              : 'privacyContainer lightTheme'
          }
        >
          <div className="privacyLeft">
            <div className="logo footer-pop-logo">
              <img src={themeType.theme === 'dark' ? LogoWhite : LogoBlue} alt="logo" />
            </div>
            <p className="policy-header">PRIVACY POLICY</p>
            <p className="policy-subheader">
              This privacy policy explains how DICV (collectively “Truckonnect”,
              “we”, “us” or “our”) collects, use, share and process your
              information.
            </p>
            {privacyData
              && privacyData.data.map(data => {
                if (data.num <= 7) {
                  return (
                    <div key={data.num}>
                      <p className="policy-level-header">
                        {data.num}){data.title}
                      </p>
                      <p
                        className="policy-subheader"
                        dangerouslySetInnerHTML={{
                          __html: data.info,
                        }}
                      />
                    </div>
                  );
                }

                return '';
              })}
          </div>
          <div className="privacyRight">
            {privacyData
              && privacyData.data.map(data => {
                if (data.num > 7) {
                  return (
                    <div key={data.num}>
                      <p className="policy-level-header">
                        {data.num}){data.title}
                      </p>
                      <p
                        className="policy-subheader"
                        dangerouslySetInnerHTML={{
                          __html: data.info,
                        }}
                      />
                    </div>
                  );
                }

                return '';
              })}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PolicyPopup;
