/* eslint-disable no-console */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Authorization from '../../Helpers/Authorization';
import Application from './Application';

/**
 * If we have a logged-in user, redirect to the home page. Otherwise, display the component.
 */
const PublicRoute = ({
  component: Component, path, uiType, ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (Authorization.isLoggedIn()) {
        if (Authorization.isAdmin()) {
          return <Redirect to={{ pathname: '/profile' }} />;
        }
        if (uiType === 'beta') {
          return <Redirect to={{ pathname: '/track-trace' }} />;
        }
        if (Authorization.getAuthUserRole() === 'CSSUPPORT') {
          return <Redirect to={{ pathname: '/cssupport' }} />;
        }

        return <Redirect to={{ pathname: '/owner-suite' }} />;
      }

      return (
        <Application
          {...props}
          isLoggedIn={Authorization.isLoggedIn}
          auth={Authorization}
          component={Component}
          path={path}
          uiType={uiType}
        />
      );
    }}
  />
);

export default PublicRoute;
