// import { color } from 'highcharts';
/* eslint-disable */

export const darkTheme = {
  headerBanner: {
    bgColor: '#141728',
    color: '#fff'
  },
  header: {
    color: '#ffffff',
    titleColor: '#ffffff',
  },
  alerts: {
    btn: {
      background: '#242844',
      color: '#ffffff',
      radius: '4px',
      border: 0,
    },
  },
  cardPrimary: {
    background: '#252A4A',
    color: '#ffffff',
    radius: '8px',
    shadow: '#2e5bff12',
  },
  cardSecondary: {
    background: '#081C32',
    color: '#ffffff',
    radius: '2px',
    border: 'none',
  },
  cardOther: {
    background: '#081C32',
    color: '#ffffff',
    radius: '8px',
  },
  datepicker: {
    background: '#141728',
    color: '#ffffff',
  },
  wrapperBackground: '#0a0e22',
  background: '#141728',
  whiteColor: '#ffffff',
  blueColor: '#0d295d',
  grayColor: '#bdbdbd',
  liveGrayColor: '#fff',
  grayWhiteColor: '#ffffff',
  bgApplication: '#F4F6FC',
  headerColor: '#94a7b6',
  buttonBg: ' #aaa',
  secondaryHeader: '#8e8e8e',
  footerText: '#656a82',
  footerBorder: 'rgb(95 143 199 / 50%)',
  profileTitle: '#4659b9',
  tableOdd: '#060818',
  tableEven: '#131c3',
  tableBorder: '#20284C',
  tabletextColor: '#a4a4a4',
  tableProfileHeader: '#4f4f4f',
  selectColor: '#fff',
  liveSelectColor: '#333333',
  reports: {
    tabHeader: '#0b1023',
    tabheaderFont: '#616472',
    tabheaderFontCol: '#ffffff',
    tableHeader: '#060818',
    tableHeaderFont: '#bdbdbd',
    tableBorder: '#20284c',
    even: '#131c34',
    odd: '#060818',
    fontTable: '#a4a4a4',
    subAlertBg: '#afafb0',
    subAlertFont: '#3f3f3f',
    border: '2px solid #20284c',
  },
  trackTrace: {
    tripCard: { bgColor: 'rgb(103 120 147)' },
    geoFencCard: { bgColor: '#0E2E50' },
    geoFencCardHead: { bgColor: '#003171' },
  },
  tripManagement: {
    createBtn: {
      color: '#007bc6',
    },
    shareBtn: {
      color: 'white',
    },
    endBtn: {
      color: '#ff0000',
    },
    tripCardHeader: {
      color: '#d7dbfd',
    },
    tripNoDataLegend: {
      color: '#ffffff',
    },
    tripTableHeader: {
      bgColor: '#081c32',
      color: '#BDBDBD',
    },
    tripTableHeaderActive: {
      bgColor:
        'linear-gradient(90deg, rgba(239,239,240,1) 50%, rgba(118,120,130,1) 100%)',
      color: 'black',
    },
    driveSearchDropdown: {
      bgColor: '#081c32',
      color: 'white',
    },
  },
  dashboardStyles: {
    mainWrapper: {
      bgColor: '#0a0e22',
    },
    ownerWrapper: {
      bgColor: '#0a0e22',
    },
    cardBackground: {
      bgColor: '#141728',
    },
    cardTitle: {
      color: '#d7dbfd',
    },
    highchartsBackground: {
      fill: 'none',
    },
    noDataLegend: {
      color: 'inherit',
    },
    dashPanel: {
      color: '#d7dbfd',
      dropIcon: '#d7dbfd',
    },
    CircularProgressbarTrail: {
      stroke: 'rgb(41, 54, 108)',
    },
    homeText: {
      color: '#4659B9',
    },
    dashboardSelect: {
      bgColor: '#0a0e22',
      color: '#ffff',
    },
    serviceInfoContainer: {
      bgColor: '#0b0c16',
      color: '#d7dbfd',
    },
    buttonSubmit: {
      bgColor: '-webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f)',
      color: '#000',
    },
    buttonCancel: {
      bgColor: '-webkit-linear-gradient(left, #e6e6e6, #6f6f6f 73%, #6f6f6f)',
      color: '#000',
    },
    initialTruck: {
      color: '#fff',
    },
    downArrow: {
      fill: '#212a54',
    },
    dropdownStyle: {
      background: '#fff',
      border: 'none',
    },
    dropdownStyleDA: {
      background: '#fff',
      color: '#bfbfc0',
      border: '2px solid #212a54',
    },
    menuSearchtruck: {
      bgColor: '#081c32',
      border: 'none',
      color: '#bdbdbd',
    },
    dropDownCalendar: {
      background: 'linear-gradient(357deg, #8f8f8f 0%, #bdbdbd 100%)',
      backgroundColor: 'grey',
    },
    inputFieldDash: {
      background: 'none',
      color: '#000',
    },
    groupDropDash: {
      color: 'opacity(0.5) drop-shadow(0 0 0 purple)',
    },
    groupDropDashChecked: {
      color: 'opacity(90) drop-shadow(0 0 0 white) contrast(50)',
    },
    inputSelectOption: {
      background: '#212a54',
      color: '#fff',
    },
    clearButton: {
      // bgColor: '#1F3276',
      // color: 'white',
    },
    dashboardStyle: {
      color: '#fff',
    },
  },
  drivingAnalysisStyles: {
    analysisTitle: {
      color: '#bdbdbd',
    },
    noDataDriving: {
      color: '#bdbdbd',
    },
    checkedStyle: {
      // // backgroundImage: 'url("../src/assets/images/Checkbox_Active-tick.png")'
      // backgroundImage: "url('../src/assets/images/checkboxActive.png')",
    },
    calenderDisabledates: {
      // color: '#000000',
    },
  },
  genericStyles: {
    dataContainer: {
      color: '#fff',
      bgColor: '#0a0e22',
    },
    footerText: {
      color: '#656a82',
    },
  },
  findMyTruckStyles: {
    mapSidebar: {
      bgColor: '#081c32',
      color: '#ffffff',
    },
    filterFmt: {
      bgColor: '#0a0e22',
      color: '#ffffff',
    },
    fmtScroll: {
      bgColor: '#0a0e22',
      color: '#ffffff',
    },
    filterBtn: {
      bgColor: '#0a0e22',
      color: '#ffffff',
    },
    statusPanel: {
      bgColor: '#0a0e22',
      color: '#ffffff',
    },
    fmtSearch: {
      border: '2px solid #0a0e22',
    },
    showBtn: {
      border: '0.1px solid #0a0e22',
    },
    footerWrapper: {
      bgColor: '#141728',
      color: '#656a82',
    },
    fmtTab: {
      color: '#004188',
    },
    locationShareLabel: {
      color: 'white',
      fontWeight: 300,
    },
    findMyTruckDropdown: {
      // bgColor: '#f2f8ff',
      // color: '#000000',
    },
    findMyTruckDropdownBtn: {
      // bgColor: '#1F3276',
      // color: '#fff'
    },
    locationShareDropDown: {
      backgroundColor: '#141728',
      color: '#bdbdbd',
      border: '2px solid #212a54',
    },
    locationShareDropDownSelected: {
      backgroundColor: '#808080',
    },
  },
  fmtGeofenceStyles: {
    geoHeader: {
      color: '#ffffff',
    },
    geoBox: {
      bgColor: '#081c32',
    },
    geoName: {
      color: '#ffffff',
    },
    locationList: {
      color: '#ffffff',
    },
  },
  reportsStyles: {
    reportsHeader: {
      bgColor: '#242844',
    },
    filterScreen: {
      bgColor: '#0a0e22',
    },
    distanceAlert: {
      bgColor: '#242844',
    },
    datePicker: {
      // bgColor: '#ffffff',
      // color: '#000000',
    },
    repButton: {
      // bgColor: '#212a54',
      // color: '#ffffff',
    },
    menuDropDown: {
      // bgColor: '#ffffff',
      // color: '#000000',
    },
    filterBlock: {
      // bgColor: '#dedede',
    },
    selectVehicleText: {
      // color: '#000000',
    },
    clearButton: {
      // bgColor: '#212a54',
      // color: '#ffffff',
    },
    reportsTable: {
      // bgColor: '#ffffff',
      // color: '#000000',
    },
    alertsNavTab: {
      // bgColor: '#fcfeff',
      // color: '#000000'
    },
    alertsNavTabActive: {
      // bgColor: '#edf8ff',
    },
    inputPlaceholderReports: {
      // bgColor: '#fff',
      // border: '1px #081c32 solid',
      // color: '#081c32',
    },
    calenderInput: {
      // bgColor: '#fff',
      // color: '#000000',
    },
    reportsTableEven: {
      // bgColor: '#fff',
      // color: '#000000'
    },
    tableBorder: {
      // border: '1px solid grey'
    },
  },
  filterInfoBoxStyles: {
    filterMessage: {
      color: '#bdbdbd',
    },
  },
  settingsStyles: {
    settingsLeftPanel: {
      bgColor: '#141728',
      color: '#4f4f4f',
    },
    settingsTableHead: {
      bgColor: '#131c34',
      color: '#bdbdbd',
      border: 'none',
    },
    settingsTableOdd: {
      bgColor: '#060818',
      color: '#bdbdbd',
      border: 'none',
    },
    settingsTableEven: {
      bgColor: '#131c34',
      color: '#bdbdbd',
      border: 'none',
    },
    accordionContent: {
      bgColor: '#0a0e22',
      color: '#4f4f4f',
    },
    activeLeftMenu: {
      bgColor: '#323232',
      color: '#fff',
    },
    settingsHeader: {
      color: '#bfbfbf',
    },
    checkBoxStyle: {
      bgColor: '#aeaeae',
      border: '1px solid #ccc',
    },
  },
  cds: {
    stepper: {
      bgColor: '#141728',
      color: '#fff',
    },
  },
  tripManagementLightTheme: {
    tripManagementTable: {
      // bgColor: '#fff',
      // color: '#000000',
    },
    createTripModal: {
      // bgColor: '#fff',
      // color: '#000000',
    },
    createTripModalSubitnBtn: {
      // bgColor: '#1F3276',
      // color: '#fff',
    },
    cancelTripButton: {
      bgColor:
        '-webkit-linear-gradient(left, #e6e6e6 0%, #6f6f6f 73%, #6f6f6f 100%)',
    },
    tripManagementCalender: {
      // bgColor: '#fff',
      // color: '#000000',
    },
    timeDropDown: {
      backgroundColor: '#141728',
      color: '#bdbdbd',
      border: '2px solid #212a54',
    },
    timeDropDownSelected: {
      backgroundColor: '#808080',
    },
    tripManagementTd: {
      bgColor: '#141728',
      color: '#fff',
    },
    sideArrowContent: {
      // bgColor: '#f2f8ff',
      // color: '#000000',
    },
    calenderInput: {
      bgColor: '#141728',
    },
    reportsTabActive: {
      borderBottom: '2px solid #bdbdbd',
    },
  },
  fleetHealth: {
    healthIndicator: {
      bgColor: '#212a54',
      borderBottom: '3px solid red',
      boxShadow: '0 8px 10px -5px red',
      borderRadius: '0px',
      innerBg: '#495583',
      innerBorder: 'none',
      innerborderRadius: '0px',
      headerColor: '#fff',
      carouselBg: '#0a0e22',
      fleetTextBold: 'normal',
      fleetCodeBg: 'none',
      fleetCodePadding: '0',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      // width: '100%',
      // justifyContent: '',
      serviceNow: '#FCC0C0',
      serviceLater: '#424242',
      serviceColor: '#bfbfc0',
      serviceCard: '#141728',
      serviceCardColor: '#bfbfc0',
      serviceCardCode: '#0a0e22',
    },
  },
  profTab: {
    bgColor: '',
    boxShadow: '',
  },
  betaAccessControl: {
    betaAccessMainWrapper: {
      bgColor: '#0a0e22',
    },
    betaContainer: {
      // bgColor: '#fff'
    },
    betaSearchInput: {
      // bgColor: '#fff',
      // border: '1px solid #a1a1a1'
    },
    betaAcessSearchBtn: {
      bgColor: 'linear-gradient(to left, #707070, #ffffff)',
      color: '#000000',
      lineHeight: '35px',
      borderRadius: '5px',
      border: 'none',
    },
    betaAccessTable: {
      bgColor: '#131c34',
      // color: '#000000'
      border: '2px solid #20284c',
    },
    betaAccessPagination: {
      // bgColor: '#F5F6FB',
      // color: '#000000'
    },
    betaAccessTableEven: {
      bgColor: '#131c34',
      color: '#a4a4a4',
    },
    betaAccessTableOdd: {
      bgColor: '#060818',
      color: '#a4a4a4',
    },
  },
  liveShare: {
    placeholderText: {
      color: 'black',
    },
    labelName: {
      color: '#dbeaff',
    },
    liveShareBtn: {
      // bgColor: '#1F3276',
      // color: 'white'
    },
    historyToggle: {
      bgColor: '#3F51B5',
    },
  },
  alphaFilter: {
    filterBox: {
      bgColor: '#141728',
      border: '1px solid #7F97B7',
      color: '#fff',
    },
  },
  locationShare: {
    bgColor: '#fff',
    opactity: '0.5',
  },
  selectedFilter: {
    bgColor: '#242844',
    border: '1px #686868 solid',
    color: '#fff',
  },
  serviceRemiander: {
    color: '#d7dbfd',
    border: '1px #1a1a1a solid',
  },
  profilePopup: {
    bgColor: '#011225',
    color: '#eee',
    panelBg: '#1f243d',
  },
  profilePopupRestore: {
    bgColor: '#011225',
    color: '#fff',
  },
  profilePopupRestoreBtn: {
    bgColor: 'linear-gradient(357deg, #8f8f8f 0%, #bdbdbd 100%)',
    color: '#fff',
  },
  fluidsGraph: {
    bgColor: '#252A4A',
    color: '#fff',
    panelBg: '#3D415E',
    boxShadow: '0px 2px 2px #000047',
    border: '2px #252A4A solid',
    legendDisable: '#5f5f5f',
    labelbgColor: '#0a0e22',
    labelColor: '#fff'
  }
};

export const lightTheme = {
  headerBanner: {
    bgColor: '#fff',
    color: '#141728',
  },
  header: {
    color: '#0d295d',
    titleColor: '#616472',
  },
  alerts: {
    btn: {
      background: '#ffffff',
      color: '#606060',
      radius: '4px',
      border: 0,
    },
  },
  cardPrimary: {
    background: '#ffffff',
    color: '#212529',
    radius: '8px',
    shadow: '#2e5bff12',
  },
  cardSecondary: {
    background: '#ffffff',
    color: '#212529',
    radius: '2px',
    border: '2px solid #f3f2f2',
  },
  cardOther: {
    background: '#ffffff',
    color: '#212529',
    radius: '16px',
  },
  datepicker: {
    background: '#ffffff',
    color: '#212529',
  },
  wrapperBackground: '#f4f6fc',
  background: '#ffffff',
  blueColor: '#0d295d',
  grayColor: '#3c3c3c',
  liveGrayColor: '',
  grayWhiteColor: '#606060',
  blackColor: '#212529',
  bgApplication: '#fff',
  headerColor: '#003171',
  buttonBg: ' #003171',
  secondaryHeader: '#4f5175',
  footerText: '#4f4f4f',
  footerBorder: '#e2e4ee',
  tableOdd: '#EEE',
  tableEven: '#fff',
  tableBorder: '#f4f6fc',
  tabletextColor: '#4F4F4F',
  tableProfileHeader: '#003171',
  selectColor: '#003171',
  liveSelectColor: '#a09898',
  reports: {
    tabHeader: '#CDE6FF',
    tabheaderFont: '#333',
    tabheaderFontCol: '#333333',
    tableHeader: '#003171',
    tableHeaderFont: '#fff',
    tableBorder: '#f4f6fc',
    even: '#E9E9E9',
    odd: '#EEE',
    fontTable: '#4F4F4F',
    subAlertBg: '#fff',
    subAlertFont: '#4F4F4F',
    border: '2px solid #f4f6fc',
  },
  trackTrace: {
    tripCard: { bgColor: '#e6eefa' },
    geoFencCard: { bgColor: '#e6eefa' },
    geoFencCardHead: { bgColor: '#003171' },
  },
  tripManagement: {
    createBtn: {
      color: '#007bc6',
    },
    shareBtn: {
      color: '#212a54',
    },
    endBtn: {
      color: '#f62d2d',
    },
    tripCardHeader: {
      color: '#4f4f4f',
    },
    tripNoDataLegend: {
      color: '#4f4f4f',
    },
    tripTableHeader: {
      bgColor: '#fff',
      color: '#4f4f4f',
    },
    tripTableHeaderActive: {
      bgColor: '#007bc6',
      color: '#fff',
    },
    driveSearchDropdown: {
      bgColor: 'rgb(255, 255, 255) !important;',
      color: 'black',
    },
  },
  dashboardStyles: {
    mainWrapper: {
      bgColor: '#F5F6FB',
    },
    ownerWrapper: {
      bgColor: '#F5F6FB',
    },
    cardBackground: {
      bgColor: '#fff',
    },
    cardTitle: {
      color: '#141728',
    },
    highchartsBackground: {
      fill: '#fff',
    },
    noDataLegend: {
      color: '#656a82',
    },
    dashPanel: {
      color: '#656a82',
      fill: '#1F3276',
    },
    CircularProgressbarTrail: {
      stroke: 'none',
    },
    homeText: {
      color: '#4659B9',
    },
    dashboardSelect: {
      bgColor: '#fff',
      color: '#1F3276',
    },
    serviceInfoContainer: {
      bgColor: '#fff',
      color: '#141728',
    },
    buttonSubmit: {
      bgColor: '#1F3276',
      color: '#fff',
    },
    buttonCancel: {
      bgColor: '#e6e6e6',
      color: '#fff',
    },
    initialTruck: {
      color: '#1F3276',
    },
    downArrow: {
      fill: '#1F3276',
    },
    dropdownStyle: {
      bgColor: '#fff',
      border: 'solid #212a54 0.1rem;',
    },
    dropdownStyleDA: {
      bgColor: '#fff',
      color: '#4f4f4f',
      border: 'solid #212a54 2px;',
    },
    menuSearchtruck: {
      bgColor: '#fff',
      border: '1px #081c32 solid',
      color: '#081c32',
    },
    dropDownCalendar: {
      background: '#fff',
      backgroundColor: '#fff',
    },
    inputFieldDash: {
      background: '#fff',
      color: '#1F3276',
    },
    groupDropDash: {
      color: 'opacity(0.5) drop-shadow(0 0 0 pink)',
    },
    groupDropDashChecked: {
      color:
        'opacity(90) drop-shadow(0 0 0 #1f3276) saturate(25) contrast(0.2)',
    },
    inputSelectOption: {
      background: '#fff',
      color: '#000',
    },
    clearButton: {
      bgColor: '#1F3276',
      color: 'white',
    },
  },
  drivingAnalysisStyles: {
    analysisTitle: {
      color: '#141728',
    },
    noDataDriving: {
      color: '#656a82',
    },
    checkedStyle: {
      // backgroundImage: 'url("../src/assets/images/Checkbox_Active-tick.png")'
      backgroundImage: 'url("../src/assets/images/checkboxActive.png")',
    },
    calenderDisabledates: {
      color: '#000000',
    },
  },
  genericStyles: {
    dataContainer: {
      color: '#0a0e22',
      bgColor: '#fff',
    },
    footerText: {
      color: '#656a82',
    },
  },
  findMyTruckStyles: {
    mapSidebar: {
      bgColor: '#ffffff',
      color: '#000000',
      border: 'none',
    },
    filterFmt: {
      bgColor: '#f2f8ff',
      color: '#000000',
    },
    fmtScroll: {
      bgColor: '#f2f8ff',
      color: '#000000',
    },
    filterBtn: {
      bgColor: '#f2f8ff',
      color: '#000000',
    },
    statusPanel: {
      bgColor: '#ffffff',
      color: '#000000',
      border: '2px solid #0a0e22',
    },
    fmtSearch: {
      border: '2px solid #0a0e22',
    },
    showBtn: {
      color: '#000000',
      border: '0.1px solid #0a0e22',
    },
    footerWrapper: {
      bgColor: '#141728',
      color: '#656a82',
    },
    fmtTab: {
      color: '#004188',
    },
    findMyTruckDropdown: {
      bgColor: '#f2f8ff',
      color: '#000000',
    },
    findMyTruckDropdownBtn: {
      bgColor: '#1F3276',
      color: '#fff',
    },
    locationShareLabel: {
      color: 'black',
      fontWeight: 400,
    },
    locationShareDropDown: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.65)',
    },
    locationShareDropDownSelected: {
      backgroundColor: '#f5f5f5',
    },
  },
  fmtGeofenceStyles: {
    geoHeader: {
      color: '#000000',
      bgColor: '#dce0e6',
    },
    geoBox: {
      bgColor: '#edf5ff',
      color: '#000000',
    },
    geoName: {
      color: '#000000',
    },
    locationList: {
      color: '#000000',
      bgColor: '#ffffff',
    },
  },
  reportsStyles: {
    reportsHeader: {
      bgColor: '#ffffff',
    },
    filterScreen: {
      bgColor: '#ffffff',
    },
    distanceAlert: {
      bgColor: '#fcfeff',
      color: '#000000',
    },
    datePicker: {
      bgColor: '#ffffff',
      color: '#000000',
    },
    repButton: {
      bgColor: '#212a54',
      color: '#ffffff',
    },
    menuDropDown: {
      bgColor: '#ffffff',
      color: '#000000',
    },
    filterBlock: {
      bgColor: '#dedede',
    },
    selectVehicleText: {
      color: '#000000',
    },
    clearButton: {
      bgColor: '#212a54',
      color: '#ffffff',
    },
    reportsTable: {
      bgColor: '#fff',
      color: '#4f4f4f',
    },
    reportsTableEven: {
      bgColor: '#f4f6fc',
      color: '#000000',
    },
    tableBorder: {
      border: '1px solid grey',
    },
    alertsNavTab: {
      bgColor: '#fcfeff',
      color: '#000000',
    },
    alertsNavTabActive: {
      bgColor: '#d8e2eb',
      color: '#1c7cd6',
    },
    inputPlaceholderReports: {
      bgColor: '#fff',
      border: '1px #081c32 solid',
      color: '#081c32',
    },
  },
  filterInfoBoxStyles: {
    filterMessage: {
      color: '#000',
    },
  },
  settingsStyles: {
    settingsLeftPanel: {
      bgColor: '#fff',
      color: '#4f4f4f',
    },
    settingsTableHead: {
      bgColor: '#f4f6fc',
      color: '#4f4f4f',
      border: '1px #e3e3e3 solid',
    },
    settingsTableOdd: {
      bgColor: '#fff',
      color: '#4f4f4f',
      border: '0.5px #e3e3e3 solid',
    },
    settingsTableEven: {
      bgColor: '#f4f6fc',
      color: '#4f4f4f',
      border: '0.5px #e3e3e3 solid',
    },
    accordionContent: {
      bgColor: '#fff',
      color: '#4f4f4f',
    },
    activeLeftMenu: {
      bgColor: '#003171',
      color: '#fff',
    },
    settingsHeader: {
      color: '#4f4f4f',
    },
    checkBoxStyle: {
      bgColor: '#85bbff',
      border: '1px solid #85bbff',
    },
  },
  cds: {
    stepper: {
      bgColor: '#fff',
      color: '#4f4f4f',
    },
  },
  tripManagementLightTheme: {
    tripManagementTable: {
      bgColor: '#fff',
      color: '#000000',
    },
    createTripModal: {
      bgColor: '#fff',
      color: '#000000',
    },
    createTripModalSubitnBtn: {
      bgColor: '#1F3276',
      color: '#fff',
    },
    cancelTripButton: {
      bgColor: '#C8C8C8',
    },
    tripManagementCalender: {
      bgColor: '#fff',
      color: '#000000',
    },
    timeDropDown: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.65)',
    },
    timeDropDownSelected: {
      backgroundColor: '#f5f5f5',
    },
    tripManagementTd: {
      bgColor: '#fff',
      color: '#000000',
    },
    sideArrowContent: {
      bgColor: '#f2f8ff',
      color: '#000000',
    },
    calenderInput: {
      bgColor: '#fff',
    },
    reportsTabActive: {
      borderBottom: '2px solid #1c7cd6',
      color: '#1c7cd6',
    },
  },
  fleetHealth: {
    healthIndicator: {
      bgColor: '#fff',
      borderBottom: '3px solid white',
      boxShadow: '0 8px 10px 5px #f1f1f1',
      borderRadius: '4px',
      innerBg: '#f4f6fc',
      innerBorder: '1px #e3e3e3 solid',
      innerBorderRadius: '8px 25px 25px 8px',
      headerColor: '#4f4f4f',
      carouselBg: '#fff',
      fleetTextBold: 'bold',
      fleetCodeBg: '#eff2f9',
      fleetCodePadding: '3px 5px',
      flexWrap: 'wrap',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItem: 'center',
      serviceNow: '#fcc0c0',
      serviceLater: '#dbdceb',
      serviceColor: '#4f4f4f',
      serviceCard: '#fff',
      serviceCardColor: '#4f4f4f',
      serviceCardCode: '#eff2f9',
    },
  },
  profTab: {
    bgColor: '#ffffff',
    boxShadow: '0 0 10px rgba(0,0,0,0.2)',
  },
  betaAccessControl: {
    betaAccessMainWrapper: {
      bgColor: '#F5F6FB',
    },
    betaContainer: {
      bgColor: '#fff',
    },
    betaSearchInput: {
      bgColor: '#fff',
      border: '1px solid #a1a1a1',
    },
    betaAcessSearchBtn: {
      bgColor: '#1F3276',
      color: '#fff',
      lineHeight: '35px',
      borderRadius: '5px',
      border: 'none',
    },
    betaAccessTable: {
      bgColor: '#F5F6FB',
      color: '#000000',
      border: '2px solid #e3e3e3',
    },
    betaAccessPagination: {
      bgColor: 'white',
      color: '#000000',
    },
    betaAccessTableEven: {
      bgColor: '#F5F6FB',
      color: '#a4a4a4',
      border: '2px solid #e3e3e3',
    },
    betaAccessTableOdd: {
      bgColor: 'white',
      color: '#a4a4a4',
      border: '2px solid #e3e3e3',
    },
  },
  liveShare: {
    placeholderText: {
      color: 'black',
    },
    labelName: {
      color: 'black',
    },
    liveShareBtn: {
      bgColor: '#1F3276',
      color: 'white',
    },
    historyToggle: {
      bgColor: '#3F51B5',
    },
  },
  alphaFilter: {
    filterBox: {
      bgColor: '#fff',
      border: '1px solid #7F97B7',
      color: '#000',
    },
  },
  locationShare: {
    bgColor: '#003171',
    opactity: '1',
  },
  selectedFilter: {
    bgColor: '#fff',
    border: '1px #ddd solid',
    color: '#000',
  },
  serviceRemiander: {
    color: '#4f4f4f',
    border: '1px #ddd solid',
  },
  profilePopup: {
    bgColor: '#fff',
    color: '#222',
    panelBg: '#fff',
  },
  profilePopupRestore: {
    // bgColor: '#011225',
    // color: '#eee',
  },

  profilePopupRestoreBtn: {
    // background: 'linear-gradient(357deg, #8f8f8f 0%, #bdbdbd 100%)',
    // color: '#fff',
  },
  fluidsGraph: {
    bgColor: '#fff',
    color: '#333',
    panelBg: '#fff',
    boxShadow: '0px 2px 2px #ccc',
    border: '2px #fff solid',
    legendDisable: '#ccc',
    labelbgColor: '#f4f6fc',
    labelColor: '#333'
  }
};
