import { DEF_SEARCH_PARAM } from '../components/Alerts/constants';

const defaultState = {
  popupData: false,
  searchParams: {
    ...DEF_SEARCH_PARAM,
  },
  filter: {
    alerts: [],
    alertsFor: 'Fleet',
    reportType: '',
    alertsForId: [],
    dateType: '',
    fromDate: '',
    toDate: '',
  },
  disableDownloadBtns: true,
  defaultReports: [],
  defaultVins: [],
  defaultVehIds: [],
  alertsInMap: [],
  showAlertsInMap: false,
};

function alertReducer(state = defaultState, action) {
  switch (action.type) {
    case 'OPEN_POPUP':
      return { ...state, popupData: action.payload };
    case 'ALR_TOGGLE_DWNLD_BTN':
      return { ...state, disableDownloadBtns: action.payload };
    case 'ALR_FILTR_VALUES':
      return {
        ...state,
        filter: action.payload,
      };
    case 'API_FILTER_VALUES':
      return {
        ...state,
        searchParams: action.payload,
      };
    case 'UPDATE_API_FILTER_VALUES':
      return {
        ...state,
        defaultReports: action.payload.defaultReports,
        defaultVehIds: action.payload.defaultVehIds,
        searchParams: {
          ...state.searchParams,
          fromTsInIST: action.payload.fromTsInIST,
          toTsInIST: action.payload.toTsInIST,
          vehicleIds: action.payload.vehicleIds,
          reportNames: action.payload.reportNames,
        },
      };
    case 'ALR_IN_MAP':
      return {
        ...state,
        alertsInMap: action.payload,
      };
    case 'SHOW_ALR_IN_MAP':
      return {
        ...state,
        showAlertsInMap: action.payload,
      };
    case 'ALR_RESET':
      return {
        ...state,
        popupData: false,
        searchParams: {
          ...DEF_SEARCH_PARAM,
        },
        filter: {
          alerts: [],
          alertsFor: 'Fleet',
          reportType: '',
          alertsForId: [],
          dateType: '',
          fromDate: '',
          toDate: '',
        },
        disableDownloadBtns: true,
        defaultReports: [],
        defaultVins: [],
        defaultVehIds: [],
        alertsInMap: [],
        showAlertsInMap: false,
      };
    default:
      return state;
  }
}

export default alertReducer;
