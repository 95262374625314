/* eslint-disable*/
import React from 'react';
import moment from 'moment';
import truck from '../../assets/images/track_trace/Truck-2x.png';
import './style.scss';

const TrucksListBeta = ({ truckData, isMobile, live }) => {
  let style = '';

  switch (truckData.vehicleStatus) {
    case 'RUNNING':
      style = '20px solid #2c8243';
      break;
    case 'IDLE':
      style = '20px solid #ffa900';
      break;
    case 'STOPPED':
      style = '20px solid #d52929';
      break;
    case 'KEYON':
      style = '20px solid gray';
      break;
    default:
      style = '20px solid darkgray';
      break;
  }

  return (
    <main
      className={
        isMobile
          ? 'd-flex cardSecondary truckcardNoHover p-1 mt-1'
          : 'd-flex cardSecondary truckcard p-1 mt-1'
      }
      style={{ borderLeft: style, fontWeight: 'bold !important' }}
    >
      <span className="truckStatus">
        {truckData.vehicleStatus ? truckData.vehicleStatus : 'OFFLINE'}
      </span>
      <img
        src={truck}
        alt="truck"
        className={live ? 'truckImglistLive' : 'truckImglist'}
      />
      <section>
        <aside className="d-flex justify-content-between">
          <div className="truck-register-id">{truckData.registrationId}</div>
          <small className="truck-register-id">
            {truckData.lastUpdatedDate
              ? moment(truckData.lastUpdatedDate)
                  .utc(false)
                  .format('MMM Do YYYY')
              : 'Not Available'}
          </small>
          <small className="truck-register-id">
            {truckData.lastUpdatedTime
              ? truckData.lastUpdatedTime
              : 'Not Available'}
          </small>
        </aside>
      </section>
    </main>
  );
};

export default TrucksListBeta;
