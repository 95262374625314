import { DEF_SEARCH_PARAM } from '../components/ReportsV2/constants';

const defaultState = {
  searchParams: {
    ...DEF_SEARCH_PARAM,
  },
  filter: {
    filterBy: 'Fleet',
    selVins: [],
    selVehIds: [],
    dateType: 'Yesterday',
    fromDate: '',
    toDate: '',
  },
  disableDownloadBtns: {
    vehicleSummary: true,
    vehicleInstant: true,
    autoTrip: true,
    hubToHub: true,
  },
  defaultVins: [],
  defaultVehIds: [],
  isSubmitButtonClicked: false,
};

function reportsReducer(state = defaultState, action) {
  switch (action.type) {
    case 'API_FLTR_REPORTS':
      return {
        ...state,
        searchParams: action.payload,
      };
    case 'UPD_API_FLTR_REPORTS':
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          vins: action.payload.vins,
          vehicleIds: action.payload.vehicleIds,
        },
        defaultVins: action.payload.defaultVins,
        defaultVehIds: action.payload.defaultVehIds,
      };
    case 'REPORTS_TOGGLE_DWNLD_BTN':
      return { ...state, disableDownloadBtns: action.payload };
    case 'FLTR_UI_STATE_REPORTS':
      return {
        ...state,
        filter: action.payload,
      };
    case 'GET_FLUID_DATA':
      return {
        ...state,
        isSubmitButtonClicked: action.payload,
      };
    default:
      return state;
  }
}

export default reportsReducer;
