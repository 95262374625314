import { DEF_SEARCH_PARAM } from '../components/DrivingAnalysisV2/constants';

const defaultState = {
  searchParams: {
    ...DEF_SEARCH_PARAM,
  },
  filter: {
    filterBy: 'Fleet',
    selVehIds: [],
    dateType: 'Yesterday',
    fromDate: '',
    toDate: '',
  },
  disableDownloadBtns: true,
  defaultVehIds: [],
};

function drivingAnalysisReducer(state = defaultState, action) {
  switch (action.type) {
    case 'API_FLTR_DA':
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          vehicleIds: action.payload.vehicleIds,
          fromTsInIST: action.payload.fromTsInIST,
          toTsInIST: action.payload.toTsInIST,
          groupNames: action.payload.groupNames,
        },
      };
    case 'UPD_API_FLTR_DA':
      return {
        ...state,
        defaultVehIds: action.payload.defaultVehIds,
        searchParams: {
          ...state.searchParams,
          vehicleIds: action.payload.vehicleIds,
        },
      };
    case 'DA_TOGGLE_DWNLD_BTN':
      return { ...state, disableDownloadBtns: action.payload };
    case 'FLTR_UI_STATE_DA':
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
}

export default drivingAnalysisReducer;
