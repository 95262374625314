/* eslint-disable */
import React from 'react';
import className from 'classnames';
import ReactTooltip from 'react-tooltip';
import truckImage from '../../assets/images/truck.png';
import dateImage from '../../assets/images/date.png';
import TrucksListBeta from './TrucksListBeta';
import { Pointer } from 'highcharts';

const LiveVehiclePane = props => {
  const {
    isExpand,
    filterDataFMT,
    isGridMap,
    isLiveTrackingEnabled,
    isInPlayBackStill,
    clientHgt,
    eachHeight,
    divHeight,
  } = props;

  const drawRoute = (vehicleId, tripDetail) => {
    props.drawRoute1(vehicleId, tripDetail);
  };

  const regIdClick = (id, status) => {
    props.regIdClickParent(id, status);
  };

  return (
    <div
      className="vehicle-scroll fmt-scroll mr-1 p-2"
      id="scrollingDiv"
      style={{ height: isExpand ? divHeight : '70vh' }}
    >
      <>
        {filterDataFMT && filterDataFMT.length > 0 ? (
          filterDataFMT.map(data => (
            <div
              onClick={() => regIdClick(data, data.vehicleStatus)}
              key={data.vehicleId}
              style={{
                height: eachHeight,
                cursor: 'pointer',
              }}
            >
              <TrucksListBeta live truckData={data}></TrucksListBeta>
            </div>
          ))
        ) : (
          <div className="no-trucks-fmt padding-no-trucks">
            {' No Trucks Found'}
          </div>
        )}
      </>
    </div>
  );
};

export default LiveVehiclePane;
