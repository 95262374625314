/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export const DEF_SEARCH_PARAM = {
  vehicleIds: [],
  fromTsInIST: moment(new Date())
    .subtract(1, 'day')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  toTsInIST: moment(new Date())
    .subtract(1, 'day')
    .endOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  reportName: [
    'DRIVER_CATEGORY_AND_SCORE',
    'DRIVER_SCORE_AND_COMPARISON',
    'DRIVING_INCIDENTS',
    'DRIVER_SCORE_TREND',
    'VEHICLE_SUMMARY_ALERT_COUNT',
  ],
  groupNames: [],
};

export const TableHeadName = [
  {
    name: 'Registration No',
    id: 'RegistrationNumber',
    minWidth: '185px',
    sort: true,
  },
  {
    name: 'Driver Score',
    id: 'DriverScore',
    isTooltip: true,
    dataTip: 'Driver Score',
    dataInfo:
      '100 - Σ (Incident score of every enabled Alerts)/No of enabled Alerts',
    minWidth: '130px',
    sort: true,
  },
  {
    name: 'Driver Score Category',
    id: 'DriverScoreCategory',
    minWidth: '160px',
  },
  {
    name: 'Total Distance',
    id: 'TotalDistance',
    sort: true,
    unitConversion: '(km)',
  },
  {
    name: 'Total Driving Time',
    id: 'TotalDrivingTime',
    convert: true,
    sort: true,
    unitConversion: '(HH:MM:SS)',
    minWidth: '170px',
  },
  {
    name: 'Fuel Consumed',
    id: 'FuelConsumed',
    sort: true,
    unitConversion: '(ltrs)',
    minWidth: '160px',
  },
  // {
  //   name: 'Adblue Consumed',
  //   id: 'AdblueConsumed',
  //   sort: true,
  //   unitConversion: '(ltrs)',
  //   minWidth: '170px',
  // },
  {
    name: 'Fuel Drop',
    id: 'FuelDrop',
    unitConversion: '(ltrs)',
    sort: true,
  },
  {
    name: 'Max Speed',
    id: 'MaxSpeed',
    sort: true,
    unitConversion: '(kmph)',
    minWidth: '130px',
  },
  {
    name: 'Overspeed',
    id: 'Overspeed',
  },
  {
    name: 'Idling',
    id: 'Idling',
  },
  {
    name: 'Neutral',
    id: 'Neutral',
  },
  {
    name: 'Harsh Acceleration',
    id: 'TOTALHA',
    sort: true,
    unitConversion: '(count)',
    minWidth: '180px',
  },
  {
    name: 'Harsh Braking',
    id: 'TOTALHB',
    sort: true,
    unitConversion: '(count)',
    minWidth: '150px',
  },
  {
    name: 'Harsh Cornering',
    id: 'TOTALHC',
    sort: true,
    unitConversion: '(count)',
    minWidth: '160px',
  },
  {
    name: 'Engine Overrun',
    id: 'EngineOverrun',
    sort: true,
    unitConversion: '(count)',
    minWidth: '160px',
  },
];

export const TableSubHeadName = [
  { name: 'Count', id: 'Count' },
  { name: 'Meters', id: 'Meters' },
  { name: 'HH:MM:SS', id: 'HH:MM:SS' },
  { name: 'Count', id: 'Count' },
  { name: 'HH:MM:SS', id: 'HH:MM:SS' },
  { name: 'Count', id: 'Count' },
  { name: 'Meters', id: 'Meters' },
  { name: 'HH:MM:SS', id: 'HH:MM:SS' },
];
