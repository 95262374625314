/* eslint-disable */
import React from 'react';

class FMTGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedGroups: props.checkedGroups,
    };
  }

  handleCheckBoxGroups = (type, e, index) => {
    const { checkedGroups, fields } = this.props;
    if (
      e.target.checked &&
      e.target.value &&
      !checkedGroups.includes(e.target.value) &&
      checkedGroups.includes('all')
    ) {
      checkedGroups.push(e.target.value);
      checkedGroups.splice(checkedGroups.indexOf('all'), 1);
    } else if (
      checkedGroups.includes(e.target.value) &&
      !checkedGroups.includes('all')
    ) {
      checkedGroups.splice(checkedGroups.indexOf(e.target.value), 1);
    } else if (e.target.value === 'all' && !checkedGroups.includes('all')) {
      checkedGroups.length = 0;
      checkedGroups.push('all');
    } else if (!checkedGroups.includes('all')) {
      checkedGroups.push(e.target.value);
    }
    if (!e.target.checked && checkedGroups.length === 0) {
      checkedGroups.push('all');
      const savedAllVehicleId = [];

      this.setState(
        {
          checked: e.target.name,
        },
        () => {
          this.props.selectAllGroups(checkedGroups, this.state.checked);
        }
      );
    }

    this.setState(
      {
        checkedGroups,
        checked: e.target.name,
      },
      () => {
        this.props.selectGroups(checkedGroups, this.state.checked);
      }
    );
  };

  render() {
    const { checkedGroups, groupData, fmtGroupList } = this.props;

    return (
      <div className="group-drop-fmt">
        <ul>
          {groupData.length > 0 ? (
            <li>
              <label className="dropdown-container">
                All
                <input
                  checked={checkedGroups.includes('all')}
                  onChange={e => {
                    this.handleCheckBoxGroups('all', e, '');
                  }}
                  type="checkbox"
                  name={'all'}
                  value={'all'}
                />
                <span className="checkmark" />
              </label>
            </li>
          ) : (
            <li className="no-data-fmt-drop">No Groups Found</li>
          )}

          {fmtGroupList &&
            fmtGroupList.length > 0 &&
            fmtGroupList.map((e, i) => {
              return (
                <li key={i}>
                  <label className="dropdown-container">
                    {e.groupName}
                    <input
                      type="checkbox"
                      name={e.groupId}
                      value={e.groupId}
                      defaultChecked={this.state.checked === e.groupId}
                      checked={checkedGroups.includes(e.groupId.toString())}
                      onChange={e => {
                        this.handleCheckBoxGroups(e.groupId, e, i);
                      }}
                    />
                    <span className="checkmark" />
                  </label>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

export default FMTGroupList;
