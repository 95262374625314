/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export const DEF_SEARCH_PARAM = {
  vins: [],
  fromTsInIST: moment(new Date())
    .subtract(7, 'days')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  toTsInIST: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  dashboardFunctionNames: [
    'MILAGE_DAILY',
    'ADBLUE_DAILY',
    'UTILIZATION_DAILY',
    'FLEET_STATUS',
    'DRIVING_SCORE',
    'SPEED_BAND',
    'RPM_BAND',
    'FLEET_INSIGHTS',
  ],
  groupNames: [],
};

// date type dropdown
export const date = [
  { id: '7days', name: 'Last 7 Days' },
  { id: '30days', name: '30 days' },
  { id: '60days', name: '60 days' },
  { id: '90days', name: '90 days' },
];

export const filterType = [
  { id: 'fleet', name: 'Fleet' },
  { id: 'vehicle', name: 'Vehicle' },
  { id: 'group', name: 'Group' },
];
