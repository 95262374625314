import React from 'react';

const ThemeSelected = React.createContext();

export function SelectedTheme({ children, data }) {
  return (
    <ThemeSelected.Provider value={data}>{children}</ThemeSelected.Provider>
  );
}

export default ThemeSelected;
