/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
import React from 'react';
import ReactGA from 'react-ga';
import isEqual from 'react-fast-compare';
import { ThemeProvider } from 'styled-components';
// Reducers-File
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { SelectedTheme } from './components/Common/Context';
import { GlobalStyles } from './global';
import { lightTheme, darkTheme } from './theme';
// Routes-File
import PrivateRoute from './components/Routes/PrivateRoute';
import PublicRoute from './components/Routes/PublicRoute';
import { ROUTE } from './config';
import Env from './Helpers/env';
// Not-Found Component
import { NotFound } from './components/NotFound';
import Authorization from './Helpers/Authorization';
import AppContainer from './AppContainer';
import LiveTrack from './components/MyFleet/MyLiveTrack';
import LiveTrackBeta from './components/Track-Trace/MyLiveTrackBeta';

export function loadJS(src) {
  const ref = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');

  script.src = src;
  script.async = true;
  ref.parentNode.insertBefore(script, ref);

  script.onerror = () => {
    document.write('Load error: Google Maps');
  };
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: localStorage.getItem('theme')
        ? localStorage.getItem('theme')
        : 'light',
      lightThemeSuprted: true,
      uiType: localStorage.getItem('uiType')
        ? localStorage.getItem('uiType')
        : 'alpha',
    };
  }

  componentWillMount() {
    // Start Google Maps API loading since we know we'll soon need it
    this.getGoogleMaps();
  }

  componentDidMount() {
    ReactGA.initialize('UA-183818462-1');
    if (Authorization.isLoggedIn()) {
      ReactGA.set({ userId: Authorization.getAuthUserId() });
    }
    this.checkModule();
    // ReactGA.set({ page: window.location.pathname });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.themeSelected, this.props.themeSelected)) {
      localStorage.setItem('theme', this.props.themeSelected);
      this.setState({
        theme: this.props.themeSelected,
      });
    }
    if (!isEqual(prevProps.uiSelected, this.props.uiSelected)) {
      localStorage.setItem('uiType', this.props.uiSelected);
      this.setState({
        uiType: this.props.uiSelected,
      });
    }
  }

  getGoogleMaps = () => {
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise(resolve => {
        // Add a global handler for when the API finishes loading
        window.resolveGoogleMapsPromise = () => {
          // Resolve the promise
          resolve(window.google);

          // Tidy up
          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${Env.env.REACT_GOOGLE_MAPS_KEY}&libraries=drawing,places`;
        script.async = true;
        document.body.appendChild(script);
      });
    }

    // Return a promise for the Google Maps API
    return this.googleMapsPromise;
  };

  checkModule = () => {
    if (
      window &&
      window.performance
        .getEntriesByType('navigation')
        .map(nav => nav.type)
        .includes('reload')
    ) {
      localStorage.setItem('reportsData', '');
    }
    if (
      !(
        window.location.pathname === '/alerts' ||
        window.location.pathname === '/reportsv2' ||
        window.location.pathname === '/driving-analysisv2' ||
        window.location.pathname === '/track-trace' ||
        window.location.pathname === '/dashboard' ||
        window.location.pathname === '/trip-management' ||
        window.location.pathname === '/liveTrack'
      )
    ) {
      this.isLightThemeSuprted(false);
    }
  };

  setRoutes = uiType => {
    const route = ROUTE;

    // let route = [];

    // if (uiType === 'alpha') {
    //   route = ROUTE.filter(route => !route.beta);
    // } else {
    //   route = ROUTE.filter(route => route.beta);
    // }

    return route.map(route => {
      if (route.private === true) {
        return (
          <PrivateRoute
            private={route.private}
            key={route.id}
            path={route.path}
            meta={route.meta}
            exact={route.exact}
            component={route.component}
            sideBar={route.sideBar}
            sideBarMenu={route.sideBarMenu}
            uiType={uiType}
          />
        );
      }

      return (
        <PublicRoute
          key={route.id}
          path={route.path}
          meta={route.meta}
          exact={route.exact}
          section={route.component}
          component={route.component}
          uiType={uiType}
        />
      );
    });
  };

  isLightThemeSuprted = action => {
    this.setState({
      lightThemeSuprted: action,
    });
  };

  render() {
    const theme = this.state.theme === 'light' ? lightTheme : darkTheme;

    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles
          darkTheme={darkTheme}
          isDarkThemeSuprted={!this.state.lightThemeSuprted}
        />
        <SelectedTheme data={this.state}>
          <Router basename={Env.getEnv('REACT_APP_HOMEPAGE')}>
            <AppContainer
              enterDarkSide={() => this.isLightThemeSuprted(false)}
              enterLightSide={() => this.isLightThemeSuprted(true)}
            >
              <Switch>
                {this.setRoutes(this.state.uiType)}
                <PublicRoute exact path="/login" component="/login" />
                <Route path="/liveTrack" component={LiveTrack} />
                <Route path="/liveTracking" component={LiveTrackBeta} />
                <NotFound default />
              </Switch>
            </AppContainer>
          </Router>
        </SelectedTheme>
      </ThemeProvider>
    );
  }
}

export default connect(state => ({
  themeSelected: state.uiState.themeSelected,
  uiSelected: state.uiState.uiSelected,
}))(App);
